import React, { useEffect, useState } from "react";
import { Table, InputNumber, Button, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BASE_API_URL } from "../../../../../utils/consts";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

interface DaySetup {
  day: string;
  hours: number;
}

type Props = {
  workingPatternDetails: any;
};

const { Option } = Select;

const WeekHoursSetup: React.FC<Props> = ({ workingPatternDetails }) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()
  const userData = useSelector((state: any) => state.userData.user);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  console.log("workingPatternDetails", workingPatternDetails);
  const [hoursSetup, setHoursSetup] = useState<DaySetup[]>(
    daysOfWeek.map((day) => ({
      day,
      hours: day !== "Saturday" && day !== "Sunday" ? 8 : 0,
    }))
  );

  useEffect(() => {
    // Set form initial values when activeNotes changes
    if (workingPatternDetails) {
      form.setFieldsValue({
        contractName: workingPatternDetails.contractName,
        type: workingPatternDetails.type,
      });
      setHoursSetup(workingPatternDetails.weekdays);
    } else {
      // Reset form fields if activeNotes is null or undefined
      form.resetFields();
    }
  }, [workingPatternDetails, form]);

  const handleHoursChange = (day: string, hours: number | undefined) => {
    if (hours !== undefined) {
      setHoursSetup((prevHoursSetup) => {
        console.log("hours", hours);
        return prevHoursSetup.map((item) =>
          item.day === day ? { ...item, hours } : item
        );
      });
    }
  };

  console.log("hoursSetup", hoursSetup);
  const columns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      render: (text: number, record: DaySetup) => (
        <InputNumber
          min={0}
          value={text}
          //   onChange={(value: any) => handleHoursChange(record.day, value)}
          onChange={(value: any) =>
            handleHoursChange(record.day, value === "" ? 0 : value)
          }
        />
      ),
    },
  ];

  const onFinish = async (values: any) => {
    console.log("values", values);
    console.log("hours", hoursSetup);
    if (params.id !== "new") {
      axios
        .put(`${BASE_API_URL}/working-patterns/${params.id}`, {
          type: values.type,
          contractName: values.contractName,
          clientId: userData.clientId,
          weekdays: hoursSetup,
        })
        .then((res) => {
          console.log("res", res);
        });
    } else {
      axios
        .post(`${BASE_API_URL}/working-patterns`, {
          type: values.type,
          contractName: values.contractName,
          clientId: userData.clientId,
          weekdays: hoursSetup,
        })
        .then((res) => {
          console.log("res", res);
          navigate("/settings");
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t("Contract Name")}
          name="contractName"
          rules={[{ required: true, message: t("Please enter contract name") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label={t("Working pattern Type")}
          rules={[
            {
              required: true,
              message: t("Please select working pattern type"),
            },
          ]}
        >
          <Select placeholder={t("Please select payment type")}>
            <Option value={"FIXED"} key={"fixed"}>
              {t("Fixed")}
            </Option>
            <Option value={"PERCENT"} key={"percent"}>
              {t("Percent")}
            </Option>
            <Option value={"HOURLY"} key={"hourly"}>
              {t("Hourly")}
            </Option>
          </Select>
        </Form.Item>
        <h2>Set Up Hours for the Week</h2>
        <Table dataSource={hoursSetup} columns={columns} pagination={false} />
        <div className="saveWorkingPattern">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("Save")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default WeekHoursSetup;

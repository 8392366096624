import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import React from "react";
import { Button, Col, Form, Row, Select, Upload } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  onUploadPhotos: any;
  patient: any;
};

const UploadPatientPhotos: React.FC<Props> = ({ onUploadPhotos, patient }) => {
  const { t } = useTranslation();
  const onFinish = (values: any) => {
    onUploadPhotos(values);
  };
  const beforeUpload = (file: any) => {
    // Prevent automatic upload and update fileList manually
    return false;
  };
  return (
    <Row>
      <Col xs={24} md={12}>
        <Form onFinish={onFinish}>
          <Form.Item
            name="file"
            label={t("Upload Photo")}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
            rules={[{ required: true, message: t("Please upload a photo!") }]}
          >
            <Upload
              beforeUpload={beforeUpload}
              showUploadList={false} // Hide the default Ant Design upload list
              //   multiple
            >
              <Button icon={<UploadOutlined />}>{t("Click to Upload")}</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit">{t("Save")}</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col className="patientPhoto" xs={24} md={12}>
        <img src={patient?.file[0]?.url} alt="Patient" />
      </Col>
    </Row>
  );
};

export default UploadPatientPhotos;

import { Button, Divider, Modal, Space, Table, TableProps, Tag } from "antd";
import React, { useEffect, useState, useTransition } from "react";
import AddLeavesModal from "../../../Components/Modals/AddLeavesModal/AddLeavesModal";
import axios from "axios";
import { BASE_API_URL } from "../../../utils/consts";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../utils/useAuth";
import { useTranslation } from "react-i18next";
import { isAdmin } from "../../../utils/permissions";

type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const LeavesSettings: React.FC<Props> = () => {
  const params = useParams();
  const { t } = useTranslation();
  const authUser: any = useAuth();
  const userData = useSelector((state: any) => state.userData.user);
  const [clientLeaves, setClientLeaves] = useState<any>(undefined);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columns: TableProps<DataType>["columns"] = [
    {
      title: t("leaveName"),
      dataIndex: "leaveName",
      key: "leaveName",
    },
    {
      title: t("activeType"),
      dataIndex: "activeType",
      key: "activeType",
      render: (text) => <a>{text}</a>,
    },
    {
      title: t("totalDays"),
      dataIndex: "totalDays",
      key: "totalDays",
    },
    {
      title: t("unit"),
      dataIndex: "unit",
      key: "unit",
      render: (text) => <a>{text}</a>,
    },
    {
      title: t("Action"),
      key: "action",
      render: (row, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              navigate(`/settings/leaves/${row._id}`);
            }}
          >
            {t("View")}
          </Button>
          <Button
            onClick={() => {
              axios
                .delete(`${BASE_API_URL}/admin/leave/${row._id}`)
                .then((res) => {
                  // dispatch(deleteWorkingPattern(row._id));
                });
            }}
          >
            {t("Delete")}
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (params.id !== "new") {
      axios
        .get(`${BASE_API_URL}/admin/${userData?.clientId}/leaves`)
        .then((res) => {
          setClientLeaves(res.data);
        });
    }
  }, [params.id]);

  console.log("clientLeaves", clientLeaves);
  return (
    <>
      {isAdmin(authUser.roles) && (
        <Button type="primary" onClick={() => navigate("/settings/leaves/new")}>
          {t("Add Leaves")}
        </Button>
      )}
      <Divider />
      <Table columns={columns} dataSource={clientLeaves} />
    </>
  );
};
export default LeavesSettings;

import React, { useEffect, useState } from "react";
import {
  Table,
  InputNumber,
  Button,
  Form,
  Input,
  Select,
  ColorPicker,
} from "antd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_API_URL } from "../../../../utils/consts";

interface DaySetup {
  day: string;
  hours: number;
}

type Props = {};

enum CountingOption {
  TOTAL_DAYS = "TOTAL_DAYS",
  WEEKDAYS_ONLY = "WEEKDAYS_ONLY",
  WEEKDAYS_AND_SATURDAY_ONLY = "WEEKDAYS_AND_SATURDAY_ONLY",
}

const { Option } = Select;

const CreateLeave: React.FC<Props> = ({}) => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color, setColor] = useState<any>("#ff0000");
  const [leavesData, setLeavesData] = useState<any>(undefined);
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()
  const userData = useSelector((state: any) => state.userData.user);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [hoursSetup, setHoursSetup] = useState<DaySetup[]>(
    daysOfWeek.map((day) => ({
      day,
      hours: day !== "Saturday" && day !== "Sunday" ? 8 : 0,
    }))
  );

  useEffect(() => {
    if (userData?.clientId && params.id !== "new") {
      axios
        .get(`${BASE_API_URL}/admin/${userData?.clientId}/leave/${params.id}`)
        .then((res) => {
          console.log("res.data,", res.data);
          setLeavesData(res.data);
        });
    }
  }, [userData, dispatch]);

  useEffect(() => {
    // Set form initial values when activeNotes changes
    if (leavesData) {
      form.setFieldsValue({
        leaveName: leavesData.clientLeave.leaveName,
        totalDays: leavesData.clientLeave.totalDays,
        unit: leavesData.clientLeave.unit,
        type: leavesData.clientLeave.activeType,
        //   type: workingPatternDetails.type,
      });
      // setHoursSetup(workingPatternDetails.weekdays);
    } else {
      // Reset form fields if activeNotes is null or undefined
      form.resetFields();
    }
  }, [leavesData, form]);

  const handleHoursChange = (day: string, hours: number | undefined) => {
    if (hours !== undefined) {
      setHoursSetup((prevHoursSetup) => {
        console.log("hours", hours);
        return prevHoursSetup.map((item) =>
          item.day === day ? { ...item, hours } : item
        );
      });
    }
  };

  console.log("hoursSetup", hoursSetup);
  const columns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
      render: (text: number, record: DaySetup) => (
        <InputNumber
          min={0}
          value={text}
          //   onChange={(value: any) => handleHoursChange(record.day, value)}
          onChange={(value: any) =>
            handleHoursChange(record.day, value === "" ? 0 : value)
          }
        />
      ),
    },
  ];

  const onFinish = async (values: any) => {
    if (params.id !== "new") {
      axios
        .put(`${BASE_API_URL}/admin/leave/${params.id}`, {
          type: values.type,
          leaveName: values.leaveName,
          clientId: userData.clientId,
          totalDays: values.totalDays,
          unit: values.unit,
          activeType: values.type,
          countingOption: values.countingOption,
        })
        .then((res) => {
          console.log("res", res);
          navigate("/settings?tab=Leaves");
        });
    } else {
      axios
        .post(`${BASE_API_URL}/admin/create/leave`, {
          type: values.type,
          leaveName: values.leaveName,
          clientId: userData.clientId,
          totalDays: values.totalDays,
          unit: values.unit,
          activeType: values.type,
          countingOption: values.countingOption,
        })
        .then((res) => {
          navigate("/settings?tab=Leaves");
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  console.log("CountingOption.TOTAL_DAYS", CountingOption.TOTAL_DAYS);
  return (
    <div className="createLeave">
      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t("Leave Name")}
          name="leaveName"
          rules={[{ required: true, message: t("Please enter leave name") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="type"
          label={t("Leave Type")}
          rules={[
            {
              required: true,
              message: t("Please select leave type"),
            },
          ]}
        >
          <Select placeholder={t("Please select payment type")}>
            <Option value={"Vacation"} key={"vacation"}>
              {t("Annual/Vacation Leave")}
            </Option>
            <Option value={"SickLeave"} key={"sickLeave"}>
              {t("Sick Leave")}
            </Option>
            <Option value={"Paternity"} key={"paternity"}>
              {t("Maternity/Paternity Leave")}
            </Option>
            <Option value={"PublicHoliday"} key={"publicHoliday"}>
              {t("Public Holiday Leave")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="countingOption"
          label={t("Leave Counting Type")}
          rules={[
            {
              required: true,
              message: t("Please select type"),
            },
          ]}
        >
          <Select placeholder={t("Please select type")}>
            <Option value={CountingOption.TOTAL_DAYS} key={"totalDays"}>
              {t("All Days")}
            </Option>
            <Option
              value={CountingOption.WEEKDAYS_AND_SATURDAY_ONLY}
              key={"totalDays"}
            >
              {t("Weekdays and Saturday")}
            </Option>
            <Option value={CountingOption.WEEKDAYS_ONLY} key={"totalDays"}>
              {t("Weekdays only")}
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t("Total days")}
          name="totalDays"
          rules={[{ required: false, message: t("Total Days") }]}
        >
          <InputNumber defaultValue={0} min={0} max={100} />
        </Form.Item>
        <Form.Item
          name="unit"
          label={t("Unit Type")}
          rules={[
            {
              required: true,
              message: t("Please select unit type"),
            },
          ]}
        >
          <Select placeholder={t("Please select unit type")}>
            <Option value={"HOURS"} key={"Hours"}>
              {t("Hours")}
            </Option>
            <Option value={"DAYS"} key={"days"}>
              {t("Days")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item name="leaveColor" label={t("Leave Color")}>
          <ColorPicker
            defaultValue={color}
            onChange={(value, hexColor) => setColor(hexColor)}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateLeave;

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: any = {
	// Events Metadata
	patients: []
};

export const patientsSlice = createSlice({
  name: 'patientsSlice',
  initialState,
  reducers: {
    getPatients(state: any, action: PayloadAction<any>) {
		state.patients = action.payload;
	},
	addPatient(state: any, action: PayloadAction<any>) {
		state.patients = [
			...state.patients,
			action.payload
		]
	},
	deletePatient(state: any, action: PayloadAction<any>) {
		const patients = state.patients
		const updatedPatients = patients.filter((item: any) => {
			return item._id !== action.payload;
		});
		state.patients = updatedPatients

	},
  }
})

// Action creators are generated for each case reducer function
export const { getPatients, addPatient, deletePatient } = patientsSlice.actions

export default patientsSlice.reducer
import { configureStore } from "@reduxjs/toolkit";
import userDataSlice from "./slices/userDataSlice";
import patientsSlice from "./slices/patientsSlice";
import loaderSlice from "./slices/loaderSlice";
import workingPatternSlice from "./slices/workingPatternSlice";
import clientLeavesSlice from "./slices/clientLeaves";

const reducer = {
  userData: userDataSlice,
  patients: patientsSlice,
  loader: loaderSlice,
  workingPattern: workingPatternSlice,
  clientLeaves: clientLeavesSlice,
};
export default configureStore({
  reducer,
});

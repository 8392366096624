import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getHours, getMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const { Option } = Select;

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  onAddLeave: (costs: any) => void;
  userDetails: any;
};

const UserLeavesModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  onAddLeave,
  userDetails,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()

  useEffect(() => {
    // Set form initial values when activeNotes changes

    // Reset form fields if activeNotes is null or undefined
    form.resetFields();
  }, [form]);
  const handleOk = () => {
    onCloseModal();
  };
  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    onAddLeave(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  console.log("userDetails", userDetails);
  return (
    <Modal
      title={t("Add Leave")}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        //   initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label={t("Description")} name="description">
          <Input />
        </Form.Item>
        {/* <Form.Item
          label={t("Leave Type")}
          name="clientLeaveType"
          rules={[{ required: true, message: t("Please enter leave type") }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          name="clientLeaveType"
          label={t("User Leaves")}
          rules={[{ required: true, message: "Please select leaves type!" }]}
        >
          <Select
            placeholder={t("Select leaves type")}
            // onChange={onGenderChange}
            showSearch // Add this prop to enable search
          >
            {userDetails?.user?.clientLeaves?.map(
              (item: any, index: number) => {
                return (
                  <Option value={item._id} key={index}>
                    {item?.leaveName}
                  </Option>
                );
              }
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="from"
          label={t("Start Date")}
          rules={[{ required: true, message: t("Please enter start date") }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="to"
          label={t("End Date")}
          rules={[{ required: true, message: t("Please enter end date") }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserLeavesModal;

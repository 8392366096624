import {
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  startOfMonth,
} from "date-fns";

export function calculateMonthlyHours(weeklySchedule: any, selectedDate: any) {
  const startDate = startOfMonth(new Date(selectedDate));
  const endDate = endOfMonth(new Date(selectedDate));

  const daysOfMonth = eachDayOfInterval({ start: startDate, end: endDate });

  // Initialize an object to store daily hours
  const dailyHours: any = {};
  daysOfMonth?.forEach((date) => {
    const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
    dailyHours[dayName] = 0;
  });

  // Iterate through each week and sum the hours for each day
  for (let i = 0; i < Math.ceil(daysOfMonth.length / 7); i++) {
    const startOfWeek = addWeeks(startDate, i);
    const endOfWeek = addWeeks(startOfWeek, 1);

    weeklySchedule?.forEach((day: any) => {
      const dayName = day.day;
      const dayDate = daysOfMonth.find(
        (date) =>
          date >= startOfWeek &&
          date < endOfWeek &&
          date.toLocaleDateString("en-US", { weekday: "long" }) === dayName
      );

      if (dayDate && dailyHours.hasOwnProperty(dayName)) {
        dailyHours[dayName] += day.hours;
      }
    });
  }

  // Calculate the total hours per month
  const monthlyTotal = Object.values(dailyHours).reduce(
    (total: any, hours: any) => total + hours,
    0
  );

  return monthlyTotal;
}

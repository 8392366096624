import axios from "axios";
import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { Card, Col, DatePicker, Row, Space, Statistic, Table } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import {
  CalendarOutlined,
  ArrowUpOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { constants } from "../../../../utils/consts";
import { calculateMonthlyHours } from "../../utils";
import { useSelector } from "react-redux";

type Props = {
  userDetails: any;
  onChangeDate: (date: any) => void;
};
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const UserPayment: React.FC<Props> = ({ userDetails, onChangeDate }) => {
  const [selectedMonth, setSelectedMonth] = useState<any>(dayjs(new Date()));
  const [totalEarned, setTotalErned] = useState<any>(0);
  const userData = useSelector((state: any) => state.userData.user);

  const { t } = useTranslation();
  console.log("userData...s", userDetails);
  console.log(
    "selectedMonth",
    calculateMonthlyHours(
      userDetails?.user?.workingPatternId?.weekdays,
      selectedMonth
    )
  );
  const paymentDetails: any = userDetails?.patientDetails
    ? []?.concat(
        ...userDetails?.patientDetails?.map((item: any) => item.notes)
      ) ?? []
    : [];
  useEffect(() => {
    setTotalErned(calculateTotalCosts(userDetails?.patientDetails));
  }, [userDetails]);
  const calculateTotalCosts = (data: any) => {
    let totalCosts = 0;
    // Loop through each patient's notes
    data?.forEach((patient: any) => {
      if (patient.notes) {
        patient?.notes?.forEach((note: any) => {
          // Add the costs to the total
          totalCosts += note.costs || 0;
        });
      }
    });
    return totalCosts;
  };

  const calculateCurrency = () => {
    if (userDetails.user.clientId.currency === "Dinar") {
      return "RSD";
    } else if (userDetails.user.clientId.currency === "Euro") {
      return "EUR";
    } else {
      return "$";
    }
  };
  const calculatePercentage = (value: any) => {
    const percentage = userDetails?.user?.amount ?? 30;
    return (percentage / 100) * value;
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("Costs"),
      dataIndex: "costs",
      key: "costs",
      render: (text) => (
        <>
          {text} {calculateCurrency()}
        </>
      ),
    },
    {
      title: t("Date"),
      dataIndex: "date",
      width: 150,
      key: "date",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
    },
  ];

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, "month"));
    onChangeDate(selectedMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, "month"));
    onChangeDate(selectedMonth.add(1, "month"));
  };

  console.log(
    "userDetails?.user.amount",
    userDetails?.user.amount,
    userDetails?.user?.workingPatternId?.weekdays
  );
  return (
    <div className="userProfilePayment">
      {/* <div>
        <label>{t("Select month")} </label>
        <DatePicker
          picker="month"
          allowClear={false}
          defaultValue={dayjs(new Date())}
          //   disabledDate={disabledDate}
          onChange={(date: any, dateString: string) => {
            setSelectedMonth(dateString);
            onChangeDate(dateString);
          }}
        />
      </div> */}
      <div className="homeDatePicker">
        <div className="prev" onClick={handlePrevMonth}>
          <LeftOutlined />
        </div>
        <div className="date">
          <DatePicker
            picker="month"
            allowClear={false}
            value={selectedMonth}
            format={"MM-YYYY"}
            //   disabledDate={disabledDate}
            onChange={(date: any, dateString: string) => {
              setSelectedMonth(date);
              onChangeDate(new Date(date));
            }}
          />
        </div>
        <div className="next" onClick={handleNextMonth}>
          <RightOutlined />
        </div>
      </div>
      <Row>
        <Col xs={24}>
          {userDetails?.user?.workingPatternId?.type === "HOURLY" ? (
            <>
              <Row gutter={16} className="paymentResults">
                <Col md={8} sm={24}>
                  <Card bordered={false}>
                    <Statistic
                      title={t("Total hours")}
                      value={Number(
                        calculateMonthlyHours(
                          userDetails?.user?.workingPatternId?.weekdays,
                          selectedMonth
                        )
                      )}
                      precision={1}
                      valueStyle={{ color: "#3f8600" }}
                      prefix={<ArrowUpOutlined />}
                      //   suffix={constants.currency}
                    />
                  </Card>
                </Col>
                <Col md={8} sm={24}>
                  <Card bordered={false}>
                    <Statistic
                      title={t("Hourly rate")}
                      value={Number(userDetails?.user.amount)}
                      precision={1}
                      valueStyle={{
                        color: "#3f8600",
                      }}
                      // prefix={renderNeedToPaidIcon()}
                      suffix={calculateCurrency()}
                    />
                  </Card>
                </Col>
                <Col md={8} sm={24}>
                  <Card bordered={false}>
                    <Statistic
                      title={t("Total")}
                      value={
                        Number(userDetails?.user.amount) *
                        Number(
                          calculateMonthlyHours(
                            userDetails?.user?.workingPatternId?.weekdays,
                            selectedMonth
                          )
                        )
                      }
                      precision={1}
                      valueStyle={{
                        color: "#3f8600",
                      }}
                      // prefix={renderNeedToPaidIcon()}
                      suffix={calculateCurrency()}
                    />
                  </Card>
                </Col>
              </Row>
            </>
          ) : userDetails?.user?.workingPatternId?.type === "FIXED" ? (
            <>
              <Row gutter={16} className="paymentResults">
                <Col md={24} sm={24}>
                  <Card bordered={false}>
                    <Statistic
                      title={t("Monthly salary")}
                      value={Number(userDetails?.user.amount)}
                      precision={1}
                      valueStyle={{
                        color: "#3f8600",
                      }}
                      // prefix={renderNeedToPaidIcon()}
                      suffix={calculateCurrency()}
                    />
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {userDetails?.user?.workingPatternId?.type === "PERCENT" ? (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Card bordered={false}>
                <Statistic
                  title={t("Total Earnings")}
                  value={totalEarned}
                  precision={1}
                  valueStyle={{ color: "#3f8600" }}
                  prefix={<ArrowUpOutlined />}
                  suffix={calculateCurrency()}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false}>
                <Statistic
                  title={
                    t("Earnings of the employee") +
                    ` ${userDetails?.user?.amount ?? 30}%`
                  }
                  value={calculatePercentage(totalEarned)}
                  precision={1}
                  valueStyle={{ color: "#3f8600" }}
                  prefix={<ArrowUpOutlined />}
                  suffix={calculateCurrency()}
                />
              </Card>
            </Col>
          </Row>
          {/* <div className="userPaymentList">
        {paymentDetails?.map((item: any) => {
          return (
            <div className="list">
              <div className="payment">
                <div className="icon">
                  <CalendarOutlined />
                </div>
                <div className="date">
                  {format(new Date(item.date), "d-MMM-yyyy")}
                </div>
                <div className="title">
                  <div>{item.title}</div>
                </div>
              </div>
              <div className="costs">{item.costs}</div>
            </div>
          );
        })}
      </div> */}
          <Table columns={columns} dataSource={paymentDetails} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UserPayment;

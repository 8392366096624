import { ResponsiveLine } from "@nivo/line";
import { Col, DatePicker, Empty, Row } from "antd";
import { endOfMonth, format, getDaysInMonth, startOfMonth } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import axios from "axios";
import { BASE_API_URL } from "../../utils/consts";
import {
  LeftOutlined,
  RightOutlined,
  BarChartOutlined,
  TeamOutlined,
  EuroOutlined,
} from "@ant-design/icons";

import "./Dashboard.scss";
import DashboardPieChart from "./Components/DashboardPieChart/DashboardPieChart";
import DashboardMoneyEarn from "./Components/DashboardMoneyEarn/DashboardMoneyEarn";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
type Props = {};

const Dashboard: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [appointments, setAppointments] = useState<any>(undefined);
  const [patients, setPatients] = useState<any>(undefined);
  const [totalCosts, setTotalCosts] = useState<any>(0);
  const userData = useSelector((state: any) => state.userData.user);
  const [selectedMonth, setSelectedMonth] = useState<any>(dayjs(new Date()));
  const [patientsInfo, setPatientsInfo] = useState<any>(undefined);
  const [patientPaymentInfo, setPatientPaymentInfo] = useState<any>(undefined);

  const fetchData = async (url: any) => {
    try {
      dispatch(setLoader(true));
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    const fetchDataForAllEndpoints = async () => {
      if (userData.clientId) {
        try {
          const [appointmentsResponse, notesResponse, patientByDateResponse] =
            await Promise.all([
              fetchData(
                `${BASE_API_URL}/${
                  userData.clientId
                }/appointments?startDate=${startOfMonth(
                  new Date(selectedMonth)
                ).toISOString()}&endDate=${endOfMonth(
                  new Date(selectedMonth)
                ).toISOString()}`
              ),
              fetchData(
                `${BASE_API_URL}/${
                  userData.clientId
                }/notes?startDate=${startOfMonth(
                  new Date(selectedMonth)
                ).toISOString()}&endDate=${endOfMonth(
                  new Date(selectedMonth)
                ).toISOString()}`
              ),
              fetchData(
                `${BASE_API_URL}/${
                  userData.clientId
                }/patient_by_date?startDate=${startOfMonth(
                  new Date(selectedMonth)
                ).toISOString()}&endDate=${endOfMonth(
                  new Date(selectedMonth)
                ).toISOString()}`
              ),
            ]);

          setAppointments(appointmentsResponse || []);

          if (notesResponse?.notes) {
            const totalCostsForAllPatients = notesResponse.notes.reduce(
              (total: any, item: any) => total + item.costs,
              0
            );

            let cardCount = 0;
            let cashCount = 0;

            notesResponse.notes.forEach((person: any) => {
              if (
                person?.paymentType?.toLowerCase() === "card" ||
                person?.paymentType?.toLowerCase() === "kartica"
              ) {
                cardCount++;
              } else if (person.paymentType?.toLowerCase() === "cash") {
                cashCount++;
              }
            });

            setPatientPaymentInfo({
              cardCount,
              cashCount,
            });

            setTotalCosts(totalCostsForAllPatients);
          } else {
            setPatientPaymentInfo({
              cardCount: 0,
              cashCount: 0,
            });

            setTotalCosts(0);
          }

          setPatients(patientByDateResponse || []);

          let maleCount = 0;
          let femaleCount = 0;
          console.log("patientByDateResponse", patientByDateResponse);
          patientByDateResponse.forEach((person: any) => {
            if (person.gender?.toLowerCase() === "male") {
              maleCount++;
            } else if (person.gender?.toLowerCase() === "female") {
              femaleCount++;
            }
          });

          setPatientsInfo({
            genderNumber: {
              male: maleCount,
              female: femaleCount,
            },
          });
        } catch (error) {
          console.error(error);
          // Handle errors if needed
        }
      }
    };

    fetchDataForAllEndpoints();
  }, [selectedMonth, userData]);

  //   useEffect(() => {
  //     if (userData.clientId) {
  //       //   dispatch(setLoader(true));
  //       axios
  //         .get(
  //           `${BASE_API_URL}/${
  //             userData.clientId
  //           }/appointments?startDate=${startOfMonth(
  //             new Date(selectedMonth)
  //           ).toISOString()}&endDate=${endOfMonth(
  //             new Date(selectedMonth)
  //           ).toISOString()}`
  //         )
  //         .then((res) => {
  //           setAppointments(res.data);
  //         })
  //         .catch((err) => console.error(err))
  //         .finally(() => {
  //           //   dispatch(setLoader(false));
  //         });
  //     }
  //   }, [selectedMonth, userData]);

  //   useEffect(() => {
  //     if (userData.clientId) {
  //       axios
  //         .get(
  //           `${BASE_API_URL}/${userData.clientId}/notes?startDate=${startOfMonth(
  //             new Date(selectedMonth)
  //           ).toISOString()}&endDate=${endOfMonth(
  //             new Date(selectedMonth)
  //           ).toISOString()}`
  //         )
  //         .then((res) => {
  //           if (res.data.notes) {
  //             const totalCostsForAllPatients = res.data.notes?.reduce(
  //               (total: any, item: any) => total + item.costs,
  //               0
  //             );
  //             let cardCount = 0;
  //             let cashCount = 0;
  //             // Iterate through the array
  //             res.data.notes?.forEach((person: any) => {
  //               // Check the gender property and update counters accordingly
  //               if (
  //                 person.paymentType === "card" ||
  //                 person.paymentType === "kartica"
  //               ) {
  //                 cardCount++;
  //               } else if (person.paymentType === "cash") {
  //                 cashCount++;
  //               }
  //             });
  //             setPatientPaymentInfo({
  //               cardCount: cardCount,
  //               cashCount: cashCount,
  //             });
  //             setTotalCosts(totalCostsForAllPatients);
  //           } else {
  //             setPatientPaymentInfo({
  //               cardCount: 0,
  //               cashCount: 0,
  //             });
  //             setTotalCosts(0);
  //           }
  //         })
  //         .catch((err) => {
  //           setTotalCosts(0);
  //           console.error(err);
  //         });
  //     }
  //   }, [selectedMonth, userData]);

  //   useEffect(() => {
  //     if (userData.clientId) {
  //       axios
  //         .get(
  //           `${BASE_API_URL}/${
  //             userData.clientId
  //           }/patient_by_date?startDate=${startOfMonth(
  //             new Date(selectedMonth)
  //           ).toISOString()}&endDate=${endOfMonth(
  //             new Date(selectedMonth)
  //           ).toISOString()}`
  //         )
  //         .then((res) => {
  //           setPatients(res.data);
  //           let maleCount = 0;
  //           let femaleCount = 0;

  //           // Iterate through the array
  //           res.data.forEach((person: any) => {
  //             // Check the gender property and update counters accordingly
  //             if (person.gender === "male") {
  //               maleCount++;
  //             } else if (person.gender === "female") {
  //               femaleCount++;
  //             }
  //           });
  //           setPatientsInfo({
  //             genderNumber: {
  //               male: maleCount,
  //               female: femaleCount,
  //             },
  //           });
  //         })
  //         .catch((err) => {
  //           setTotalCosts(0);
  //           console.error(err);
  //         });
  //     }
  //   }, [selectedMonth, userData]);

  const calculateCurrency = () => {
    if (userData.client?.currency === "Dinar") {
      return "RSD";
    } else if (userData.client?.currency === "Euro") {
      return "EUR";
    } else {
      return "$";
    }
  };
  const countAppointmentsByDay = (appointments: any) => {
    const appointmentsByDay = Array(
      getDaysInMonth(new Date(selectedMonth))
    ).fill(0); // Assuming a maximum of 31 days in a month

    appointments.forEach((appointment: any) => {
      const date = new Date(appointment.date);
      const day = date.getDate();

      // Increment the count for the corresponding day
      appointmentsByDay[day - 1]++;
    });

    return appointmentsByDay;
  };

  //   Generate data for Nivo line chart
  const generateChartData = (appointmentsByDay: any) => {
    return appointmentsByDay?.map((count: any, index: any) => ({
      x: index + 1,
      y: count,
    }));
  };

  const renderTotalAppointments = useMemo(() => {
    if (appointments?.length) {
      const appointmentsByDay =
        appointments && countAppointmentsByDay(appointments);
      return [
        {
          id: t("Appointments"),
          color: "hsl(116, 70%, 50%)",
          data: generateChartData(appointmentsByDay),
        },
      ];
    }
  }, [appointments]);

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, "month"));
  };

  return (
    <div className="dashboardContainer">
      <div className="homeDatePicker">
        <div className="prev" onClick={handlePrevMonth}>
          <LeftOutlined />
        </div>
        <div className="date">
          <DatePicker
            picker="month"
            allowClear={false}
            value={selectedMonth}
            //   disabledDate={disabledDate}
            onChange={(date: any, dateString: string) => {
              setSelectedMonth(dayjs(dateString));
            }}
          />
        </div>
        <div className="next" onClick={handleNextMonth}>
          <RightOutlined />
        </div>
      </div>
      <div className="homeStatisticCard">
        <div className="card">
          <div className="cardBody">
            <div className="icon">
              <BarChartOutlined />
            </div>
            <div className="content">
              {t("Appointments")}
              <span>{appointments?.length ?? "-"}</span>
            </div>
          </div>
        </div>
        {/* End Card */}
        <div className="card">
          <div className="cardBody">
            <div className="icon">
              <TeamOutlined />
            </div>
            <div className="content">
              {t("New Patients")}
              <span>{patients?.length ? `+${patients.length}` : "-"}</span>
            </div>
          </div>
        </div>
        {/* End Card */}
        <div className="card">
          <div className="cardBody">
            <div className="icon">
              {/* <EuroOutlined /> */}
              {calculateCurrency()}
            </div>
            <div className="content">
              {t("Earnings")}
              <span>{totalCosts} </span>
            </div>
          </div>
        </div>
        {/* End Card */}
      </div>
      {renderTotalAppointments?.length ? (
        <>
          <div className="dashboardTitle">
            {t("Total number appointments in the active month")}
          </div>
          <div style={{ height: 300, width: "100%", position: "relative" }}>
            <ResponsiveLine
              data={renderTotalAppointments ?? []}
              margin={{ top: 50, right: 10, bottom: 50, left: 30 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              yFormat=" >-.2f"
              axisTop={null}
              axisRight={null}
              colors={["#3399cc", "#66b2ff"]}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: "middle",
              }}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              enableArea={true}
              enableGridX={false}
              isInteractive={false}
              enablePoints={false}
              useMesh={true}
            />
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="dashboardTitle">
            {t("Total number appointments in the active month")}
          </div>
          <div className="noData">
            <Empty description={<>{t("No Data")}</>} />
          </div>
        </>
      )}
      <Row>
        <Col span={12}>
          <div className="dashboardTitle">{t("Patients genders")}</div>
          <DashboardPieChart patientsInfo={patientsInfo} />
        </Col>
        <Col span={12}>
          <div className="dashboardTitle">{t("Patients pay type")}</div>
          <DashboardMoneyEarn patientPaymentInfo={patientPaymentInfo} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import WeekHoursSetup from "./Components/WeekHoursSetup/WeekHoursSetup";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_API_URL } from "../../../utils/consts";

const WorkingPatternDetails = () => {
  const params = useParams();
  const [workingPatternDetails, setWorkingPatternDetails] =
    useState<any>(undefined);
  useEffect(() => {
    if (params.id !== "new") {
      axios.get(`${BASE_API_URL}/working-patterns/${params.id}`).then((res) => {
        setWorkingPatternDetails(res.data);
      });
    }
  }, [params.id]);
  return (
    <>
      <WeekHoursSetup
        workingPatternDetails={workingPatternDetails?.workingPattern}
      />
    </>
  );
};

export default WorkingPatternDetails;

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: any = {
	// Events Metadata
	user: {
		accessToken: String
	}
};

export const userDataSlice = createSlice({
  name: 'userDataSlice',
  initialState,
  reducers: {
    setUserData(state: any, action: PayloadAction<any>) {
		state.user = action.payload;
	},
  }
})

// Action creators are generated for each case reducer function
export const { setUserData } = userDataSlice.actions

export default userDataSlice.reducer
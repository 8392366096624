import { Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LeavesSettings from "./LeavesSettings/LeavesSettings";
import WorkingPattern from "./WorkingPattern/WorkingPattern";
import GeneralSettings from "./GeneralSettings/GeneralSettings";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./Settings.scss";
import LocationsSettings from "./LocationsSettings/LocationsSettings";
type Props = {};
const Settings: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>("general");
  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("tab");
  useEffect(() => {
    const tabValue = searchParams.get("tab");
    setActiveTab(tabValue);
    if (!tabValue) {
      navigate(`/settings?tab=general`);
    }
    // navigate(`/settings?tab=${key}`);
  }, [activeTab, searchParams]);

  // Now, tabValue contains the value of the "tab" parameter
  console.log("searchParams", searchParams);
  const items: TabsProps["items"] = [
    {
      key: "general",
      label: t("General"),
      children: <GeneralSettings />,
    },
    {
      key: "Locations",
      label: t("Locations"),
      children: <LocationsSettings />,
    },
    {
      key: "workingPattern",
      label: t("Working Pattern"),
      children: <WorkingPattern />,
    },
    {
      key: "Leaves",
      label: t("Leaves"),
      children: <LeavesSettings />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
    navigate(`/settings?tab=${key}`);
    setActiveTab(key);
  };
  return (
    <div className="mainContainer">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default Settings;

import axios from "axios";
import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { Button, Divider, Space, Table } from "antd";

import "./Staff.css";
import { useNavigate } from "react-router-dom";
import { BASE_API_URL } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../utils/useAuth";
import { isAdmin } from "../../utils/permissions";
import AddUserModal from "../../Components/Modals/AddUserModal/AddUserModal";
import { setWorkingPatterns } from "../../utils/redux/slices/workingPatternSlice";
type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const Staff: React.FC<Props> = () => {
  const [patients, setPatients] = useState<any>(undefined);
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData.user);
  const [isOpenUserModal, setIsOpenUserModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const loggedInUser: any = localStorage.getItem("user");
  const { t } = useTranslation();
  const authUser: any = useAuth();
  useEffect(() => {
    if (userData.clientId) {
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/users`)
        .then((res) => {
          setPatients(res.data);
        })
        .catch((error) => console.error(error));
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.clientId) {
      axios
        .get(`${BASE_API_URL}/${userData?.clientId}/working-patterns`)
        .then((res) => {
          dispatch(setWorkingPatterns(res.data));
        });
    }
  }, [userData, dispatch]);

  const columns: ColumnsType<DataType> = [
    {
      title: t(""),
      key: "userAvatar",
      render: (row) =>
        row?.avatar?.url ? (
          <div className="userAvatar" style={{ borderColor: row?.color }}>
            <img src={row?.avatar?.url} alt="Avatar" />
          </div>
        ) : (
          <div>
            <span
              className="userColor"
              style={{ background: row.color }}
            ></span>
          </div>
        ),
    },
    {
      title: t("Name"),
      key: "name",
      render: (row) => (
        <div>
          {row.firstName} {row.lastName}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Phone Number"),
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record: any) => (
        <Space size="middle">
          {isAdmin(authUser.roles) && (
            <>
              <Button onClick={() => navigate(`/users/${record._id}`)}>
                {t("User Profile")} {record.name}
              </Button>
              {/* DODATI BRISANJE ZAPOSLENIH */}
              {/* <Button>{t("Delete")}</Button> */}
            </>
          )}
        </Space>
      ),
    },
  ];

  const onAddUser = (data: any) => {
    console.log("data", data);
    axios
      .post(`${BASE_API_URL}/auth/signup`, {
        email: data.email,
        password: data.password,
        clientId: userData.clientId,
        color: data.color,
        position: data.position,
        roles: [data.role],
        firstName: data.firstName,
        lastName: data.lastName,
        amount: data.amount,
      })
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/${userData.clientId}/users`)
          .then((res) => {
            setPatients(res.data);
          })
          .catch((error) => console.error(error))
          .finally(() => setIsOpenUserModal(!isOpenUserModal));
      })
      .catch((error) => console.error(error));
  };
  const onCloseModal = () => {
    setIsOpenUserModal(!isOpenUserModal);
  };
  const addUserModal = () => {
    setIsOpenUserModal(!isOpenUserModal);
  };
  return (
    <div className="mainContainer">
      {isAdmin(authUser.roles) && (
        <Button type="primary" onClick={() => addUserModal()}>
          {t("Add Employee")}
        </Button>
      )}
      <Divider />
      <AddUserModal
        isOpen={isOpenUserModal}
        onCloseModal={onCloseModal}
        onAddUser={onAddUser}
      />
      <Table columns={columns} dataSource={patients} />
    </div>
  );
};

export default Staff;

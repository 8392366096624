import { Tag } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, CameraOutlined } from "@ant-design/icons";
import {
  addWeeks,
  eachDayOfInterval,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
type Props = {
  userDetails: any;
  onUpdateUser: () => void;
};
const UserProfileData: React.FC<Props> = ({ userDetails, onUpdateUser }) => {
  const { t } = useTranslation();

  const totalWeekHours = useMemo(() => {
    return userDetails?.user?.workingPatternId?.weekdays?.reduce(
      (acc: any, day: any) => acc + day.hours,
      0
    );
  }, [userDetails?.user]);

  function calculateMonthlyHours(weeklySchedule: any) {
    const startDate = startOfMonth(new Date());
    const endDate = endOfMonth(new Date());

    const daysOfMonth = eachDayOfInterval({ start: startDate, end: endDate });

    // Initialize an object to store daily hours
    const dailyHours: any = {};
    daysOfMonth?.forEach((date) => {
      const dayName = date.toLocaleDateString("en-US", { weekday: "long" });
      dailyHours[dayName] = 0;
    });

    // Iterate through each week and sum the hours for each day
    for (let i = 0; i < Math.ceil(daysOfMonth.length / 7); i++) {
      const startOfWeek = addWeeks(startDate, i);
      const endOfWeek = addWeeks(startOfWeek, 1);

      weeklySchedule?.forEach((day: any) => {
        const dayName = day.day;
        const dayDate = daysOfMonth.find(
          (date) =>
            date >= startOfWeek &&
            date < endOfWeek &&
            date.toLocaleDateString("en-US", { weekday: "long" }) === dayName
        );

        if (dayDate && dailyHours.hasOwnProperty(dayName)) {
          dailyHours[dayName] += day.hours;
        }
      });
    }

    // Calculate the total hours per month
    const monthlyTotal = Object.values(dailyHours).reduce(
      (total: any, hours: any) => total + hours,
      0
    );

    return monthlyTotal;
  }

  const totalHoursPerMonth = calculateMonthlyHours(
    userDetails?.user?.workingPatternId?.weekdays
  );
  console.log(
    "userDetails?.user?.contractStart",
    userDetails?.user?.contractStart
  );
  return (
    <>
      <div className="userInfoBody">
        <div className="editUser" onClick={() => onUpdateUser()}>
          <EditOutlined />
        </div>
        <h3>{t("Basic Info")}</h3>
        <div className="containerBody">
          <div className="info">
            {t("First Name")}:<span>{userDetails?.user?.firstName}</span>
          </div>
          <div className="info">
            {t("Last Name")}:<span>{userDetails?.user?.lastName}</span>
          </div>
          <div className="info">
            {t("Name")}:<span>{userDetails?.user?.username}</span>
          </div>
          <div className="info">
            {t("Email")}:<span>{userDetails?.user?.email}</span>
          </div>
          <div className="info">
            {t("Gender")}:<span>{t(userDetails?.user?.gender)}</span>
          </div>
          <div className="info">
            {t("Position")}:
            <span>
              <Tag
                color={userDetails?.user?.color}
                key={userDetails?.user?.position}
              >
                {t(userDetails?.user?.position)}
              </Tag>
            </span>
          </div>
        </div>
      </div>
      <div className="userInfoBody">
        <div className="editUser" onClick={() => onUpdateUser()}>
          <EditOutlined />
        </div>
        <h3>{t("Contract Info")}</h3>
        <div className="containerBody">
          <div className="info">
            {t("Contract Name")}:
            <span>{userDetails?.user?.workingPatternId?.contractName}</span>
          </div>
          <div className="info">
            {t("Contract Type")}:
            <span>{userDetails?.user?.workingPatternId?.type}</span>
          </div>
          <div className="info">
            {t("Weekly Hours")}:<span>{totalWeekHours}</span>
          </div>
          <div className="info">
            {t("Contract start")}:
            <span>
              {format(
                new Date(userDetails?.user?.contractStart ?? new Date()),
                "dd-MM-yyy"
              )}
            </span>
          </div>

          {/* <div className="info">
            {t("Total Hours This Month")}:
            <span>{Number(totalHoursPerMonth)}</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UserProfileData;

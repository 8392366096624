import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../../utils/consts";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Table, { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { Tag } from "antd";
import { useDispatch } from "react-redux";
import { setLoader } from "../../utils/redux/slices/loaderSlice";

type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const Objectives: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user") ?? undefined;
  const clientId = user && JSON.parse(user)?.clientId;
  const [objectives, setObjectives] = useState<any>(undefined);
  useEffect(() => {
    dispatch(setLoader(true));
    axios
      .get(`${BASE_API_URL}/${clientId}/objectives`)
      .then((res) => {
        setObjectives(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoader(false)));
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: t("Name"),
      dataIndex: "userId",
      key: "name",
      render: (row) => row?.username,
    },
    {
      title: t("deadline"),
      dataIndex: "deadline",
      key: "deadline",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (value, status) => {
        const color =
          value === "COMPLETED"
            ? "green"
            : value === "INCOMPLETED"
            ? "orange"
            : "red";
        return (
          <>
            <Tag color={color} key={value}>
              {t(value)}
            </Tag>
          </>
        );
      },
    },

    // {
    //   title: "",
    //   key: "action",
    //   render: (row, record: any) => {
    //     return (
    //       <>
    //         <Space size="middle">
    //           <Button onClick={() => onUpdateLeave("APPROVED", record._id)}>
    //             <CheckOutlined />
    //           </Button>
    //           <Button onClick={() => onUpdateLeave("CANCEL", record._id)}>
    //             <CloseOutlined />
    //           </Button>
    //           <Button onClick={() => onDeleteLeave(record._id)}>
    //             <DeleteOutlined />
    //           </Button>
    //         </Space>
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <div className="mainContainer">
      <Table columns={columns} dataSource={objectives} />
    </div>
  );
};

export default Objectives;

import { Button, Divider, Space, Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../utils/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin } from "../../../utils/permissions";
import axios from "axios";
import { BASE_API_URL } from "../../../utils/consts";

type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const LocationsSettings: React.FC<Props> = () => {
  const params = useParams();
  const { t } = useTranslation();
  const authUser: any = useAuth();
  const userData = useSelector((state: any) => state.userData.user);
  const [locations, setLocations] = useState<any>(undefined);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.id !== "new") {
      axios
        .get(`${BASE_API_URL}/${userData?.clientId}/locations`)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [params.id]);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (row, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              navigate(`/settings/location/${row._id}`);
            }}
          >
            {t("View")}
          </Button>
          <Button
            onClick={() => {
              axios
                .delete(`${BASE_API_URL}/location/${row._id}`)
                .then((res) => {
                  axios
                    .get(`${BASE_API_URL}/${userData?.clientId}/locations`)
                    .then((res) => {
                      setLocations(res.data);
                    })
                    .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
            }}
          >
            {t("Delete")}
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <>
      <>
        {isAdmin(authUser.roles) && (
          <Button
            type="primary"
            onClick={() => navigate("/settings/location/new")}
          >
            {t("Add Location")}
          </Button>
        )}
        <Divider />
        <Table columns={columns} dataSource={locations} />
      </>
    </>
  );
};

export default LocationsSettings;

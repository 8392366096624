import { Button, Form, Input, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_API_URL } from "../../../utils/consts";

const { Option } = Select;

type Props = {};
const LocationDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()
  const [locationsData, setLocationsData] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);

  useEffect(() => {
    if (userData?.clientId && params.id !== "new") {
      axios
        .get(`${BASE_API_URL}/location/${params.id}`)
        .then((res) => {
          setLocationsData(res.data);
        })
        .catch((err: any) => console.error(err));
    }
  }, [userData, params.id]);

  useEffect(() => {
    // Set form initial values when activeNotes changes
    if (locationsData) {
      form.setFieldsValue({
        name: locationsData.name,

        //   type: workingPatternDetails.type,
      });
      // setHoursSetup(workingPatternDetails.weekdays);
    } else {
      // Reset form fields if activeNotes is null or undefined
      form.resetFields();
    }
  }, [locationsData, form]);

  const onFinish = async (values: any) => {
    console.log("values", values);
    if (params.id !== "new") {
      axios
        .put(`${BASE_API_URL}/location/${params.id}`, {
          name: values.name,
          clientId: userData.clientId,
        })
        .then((res) => {
          console.log("res", res);
          navigate("/settings?tab=Locations");
        });
    } else {
      axios
        .post(`${BASE_API_URL}/location/create`, {
          name: values.name,
          clientId: userData.clientId,
        })
        .then((res) => {
          console.log("res", res);
          navigate("/settings?tab=Locations");
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <>
      <div>
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("Location Name")}
            name="name"
            rules={[
              { required: true, message: t("Please enter location name") },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="saveWorkingPattern">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("Save")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default LocationDetails;

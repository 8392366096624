import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  // Events Metadata
  clientLeaves: undefined,
};

export const clientLeavesSlice = createSlice({
  name: "clientLeavesSlice",
  initialState,
  reducers: {
    setClientLeaves(state: any, action: PayloadAction<any>) {
      return {
        ...state,
        clientLeaves: action.payload,
      };
    },
    deleteClientLeaves(state: any, action: PayloadAction<any>) {
      console.log("ddreeducer", action.payload);
      console.log("state2", state.contracts);
      //   state.contracts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setClientLeaves, deleteClientLeaves } =
  clientLeavesSlice.actions;

export default clientLeavesSlice.reducer;

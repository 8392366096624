import { Button, Col, Divider, Row, Select, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import UserLeavesModal from "../../../../Components/Modals/UserLeavesModal/UserLeavesModal";
import axios from "axios";
import { BASE_API_URL } from "../../../../utils/consts";
import {
  addYears,
  differenceInDays,
  eachDayOfInterval,
  endOfYear,
  format,
  getDay,
  isFriday,
  isMonday,
  isWeekend,
  parseISO,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";
import { useTranslation } from "react-i18next";
import Table, { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { WarningOutlined } from "@ant-design/icons";
import { isAdmin } from "../../../../utils/permissions";
import "./UserLeaves.scss";
import { useAuth } from "../../../../utils/useAuth";
type Props = {
  userDetails: any;
};

const { Option } = Select;

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

enum CountingOption {
  TOTAL_DAYS,
  WEEKDAYS_ONLY,
  WEEKDAYS_AND_SATURDAY_ONLY,
}

const UserLeaves: React.FC<Props> = ({ userDetails }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [leaves, setLeaves] = useState<any>(undefined);
  const [leaveModalOpen, setLeaveModalOpen] = useState<any>(false);
  const [activeYear, setActiveYear] = useState<any>(format(new Date(), "yyyy"));
  const [counts, setCounts] = useState<{ [key: string]: number }>({});
  const authUser: any = useAuth();
  const [leaveDateType, setLeaveDateType] = useState<any>(
    CountingOption.WEEKDAYS_ONLY
  );
  console.log("authUser", authUser);
  useEffect(() => {
    axios
      .get(
        `${BASE_API_URL}/${params.id}/user_details_leaves?fromDate=${endOfYear(
          activeYear
        )}&toDate=${startOfYear(activeYear)}`
      )
      .then((res) => {
        setLeaves(res.data);
      })
      .catch((error) => console.error(error));
  }, [activeYear, params.id]);

  useEffect(() => {
    const calculateCounts = (countingOption: CountingOption) => {
      const newCounts: { [key: string]: number } = {};

      // Filter only approved leaves
      const approvedLeaves = leaves?.filter(
        (leave: any) => leave.isApproved === "APPROVED"
      );

      approvedLeaves?.forEach((item: any) => {
        if (item.clientLeaveId && item.clientLeaveId.leaveName) {
          const startDate = parseISO(item.from);
          const endDate = parseISO(item.to);
          let diffDays = differenceInDays(endDate, startDate);

          if (countingOption === CountingOption.WEEKDAYS_ONLY) {
            // Counting only weekdays, including the last day
            for (let i = 0; i <= diffDays; i++) {
              const currentDate = new Date(
                startDate.getTime() + i * 24 * 60 * 60 * 1000
              );
              if (!isWeekend(currentDate)) {
                newCounts[item.clientLeaveId.leaveName] =
                  (newCounts[item.clientLeaveId.leaveName] || 0) + 1;
              }
            }
          } else if (
            countingOption === CountingOption.WEEKDAYS_AND_SATURDAY_ONLY
          ) {
            // Counting only weekdays and Saturday, including the last day
            for (let i = 0; i <= diffDays; i++) {
              console.log("i", i, diffDays);
              const currentDate = new Date(
                startDate.getTime() + i * 24 * 60 * 60 * 1000
              );
              console.log("currentDate", currentDate);
              const dayOfWeek = currentDate.getDay();
              console.log("dayOfWeek", dayOfWeek, dayOfWeek !== 0);
              if (dayOfWeek !== 0) {
                newCounts[item.clientLeaveId.leaveName] =
                  (newCounts[item.clientLeaveId.leaveName] || 0) + 1;
              }
            }
          } else {
            // Default: Counting total days
            newCounts[item.clientLeaveId.leaveName] =
              (newCounts[item.clientLeaveId.leaveName] || 0) + diffDays;
          }
        }
      });
      console.log("newCounts", newCounts);
      setCounts(newCounts);
    };

    calculateCounts(leaveDateType);
  }, [leaves]);

  console.log("counts", counts);

  const onDeleteLeave = (leaveId: any) => {
    axios
      .delete(`${BASE_API_URL}/${leaveId}/leave_delete`)
      .then((res) => {
        axios
          .get(
            `${BASE_API_URL}/${
              params.id
            }/user_details_leaves?fromDate=${endOfYear(
              activeYear
            )}&toDate=${startOfYear(activeYear)}`
          )
          .then((res) => {
            setLeaves(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const onUpdateLeave = (status: any, leaveId: any) => {
    axios
      .put(`${BASE_API_URL}/${leaveId}/leave_update`, {
        isApproved: status,
      })
      .then((res) => {
        axios
          .get(
            `${BASE_API_URL}/${
              params.id
            }/user_details_leaves?fromDate=${endOfYear(
              activeYear
            )}&toDate=${startOfYear(activeYear)}`
          )
          .then((res) => {
            setLeaves(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const getTotalWeekdaysBetweenDates = (
    startDate: Date,
    endDate: Date
  ): number => {
    const daysInRange = eachDayOfInterval({ start: startDate, end: endDate });

    // Count weekdays (Monday to Friday) between the dates
    const weekdaysCount = daysInRange.reduce((count, day) => {
      if (!isWeekend(day)) {
        count++;
      }
      return count;
    }, 0);

    return weekdaysCount;
  };

  const getWeekdaysAndSaturdayCountBetweenDates = (
    startDate: Date,
    endDate: Date
  ): number => {
    const daysInRange = eachDayOfInterval({ start: startDate, end: endDate });

    // Count weekdays (Monday to Friday) and Saturday between the dates
    const weekdaysAndSaturdayCount = daysInRange.reduce((count, day) => {
      const dayOfWeek = day.getDay();
      if (dayOfWeek !== 0) {
        // Exclude Sundays and include Saturdays
        count++;
      }
      return count;
    }, 0);

    return weekdaysAndSaturdayCount;
  };

  const getTotalDays = (row: any) => {
    if (leaveDateType === CountingOption.WEEKDAYS_ONLY) {
      return getTotalWeekdaysBetweenDates(row.to, row.from);
    } else if (leaveDateType === CountingOption.WEEKDAYS_AND_SATURDAY_ONLY) {
      return getWeekdaysAndSaturdayCountBetweenDates(row.to, row.from);
    } else {
      return differenceInDays(row.to, row.from);
    }
  };

  const columns: ColumnsType<DataType> = [
    // {
    //   title: t("Name"),
    //   dataIndex: "userId",
    //   key: "name",
    //   render: (row) => row?.username,
    // },
    {
      title: t("Date Start"),
      dataIndex: "from",
      key: "from",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Date End"),
      dataIndex: "to",
      key: "to",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Total Days"),
      dataIndex: "totalDays",
      key: "totalDays",
      render: (value, row: any) => {
        return getTotalDays(row).toString();
      },
    },
    {
      title: t("Leave Type"),
      dataIndex: "clientLeaveId",
      key: "clientLeaveId",
      render: (value, row: any) => {
        return `${row?.clientLeaveId?.leaveName ?? ""}`;
      },
    },
    {
      title: "",
      dataIndex: "warning",
      key: "warning",
      render: (value, row: any) => {
        return counts[row?.clientLeaveId?.leaveName] + getTotalDays(row) >
          row?.clientLeaveId?.totalDays && row.isApproved !== "CANCEL" ? (
          <div className="warningLeave">
            <WarningOutlined />
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "isApproved",
      key: "isApproved",
      render: (value, status) => {
        const color =
          value === "APPROVED"
            ? "green"
            : value === "PENDING"
            ? "orange"
            : "red";
        return (
          <>
            <Tag color={color} key={value}>
              {t(value)}
            </Tag>
          </>
        );
      },
    },

    {
      title: "",
      key: "action",
      render: (row, record: any) => (
        <>
          <Space size="middle">
            {isAdmin(authUser?.roles) && (
              <>
                <Button onClick={() => onUpdateLeave("APPROVED", record._id)}>
                  <CheckOutlined />
                </Button>
                <Button onClick={() => onUpdateLeave("CANCEL", record._id)}>
                  <CloseOutlined />
                </Button>
              </>
            )}
            <Button onClick={() => onDeleteLeave(record._id)}>
              <DeleteOutlined />
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const onAddLeave = (leave: any) => {
    axios
      .post(`${BASE_API_URL}/leaves/create`, {
        description: leave.description,
        clientLeaveType: leave.clientLeaveType,
        from: leave.from,
        to: leave.to,
        userId: userDetails?.user?._id,
        clientId: userDetails?.user?.clientId?._id,
      })
      .then((res) => {
        axios
          .get(
            `${BASE_API_URL}/${
              params.id
            }/user_details_leaves?fromDate=${endOfYear(
              activeYear
            )}&toDate=${startOfYear(activeYear)}`
          )
          .then((res) => {
            setLeaves(res.data);
          });
        setLeaveModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onSelectYear = (params: any) => {
    setActiveYear(format(new Date(params), "yyyy"));
  };

  const getLeaveType = (type: any) => {
    const findLeaveType = userDetails?.user?.clientLeaves?.find(
      (item: any) => item.leaveName === type
    );
    console.log("findLeaveType", findLeaveType);
    return findLeaveType;
  };
  console.log("Object.entries(counts)", Object.entries(counts));

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Button onClick={() => setLeaveModalOpen(true)}>
            {t("Request Leave")}
          </Button>
        </Col>
        <Col>
          <Select
            placeholder={t("Select year")}
            value={activeYear}
            onChange={onSelectYear}
          >
            <Option value={`${subYears(new Date(), 1)}`} key={`3`}>
              {`${format(subYears(new Date(), 1), "yyyy")}`}
            </Option>
            <Option value={`${new Date()}`} key={"2"}>
              {`${format(new Date(), "yyyy")}`}
            </Option>
            <Option value={`${addYears(new Date(), 1)}`} key={`1`}>
              {`${format(addYears(new Date(), 1), "yyyy")}`}
            </Option>
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row className="totalDaysContainer">
        {Object.entries(counts).map(([activeType, count]) => (
          <Col key={activeType}>
            <i>{activeType}</i>
            <div className="days">
              {count} / {getLeaveType(activeType)?.totalDays}{" "}
              <span>{getLeaveType(activeType)?.unit.toLowerCase()}</span>
            </div>
          </Col>
        ))}
      </Row>

      <Table columns={columns} dataSource={leaves} />
      <UserLeavesModal
        isOpen={leaveModalOpen}
        userDetails={userDetails}
        onCloseModal={() => setLeaveModalOpen(false)}
        onAddLeave={onAddLeave}
      />
    </>
  );
};

export default UserLeaves;

import React, { useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Divider,
  Flex,
  Modal,
  Select,
  Space,
  TimePicker,
} from "antd";
import { Button, Form, Input } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  addHours,
  endOfToday,
  format,
  getDay,
  getDaysInMonth,
  getHours,
  getMinutes,
  isBefore,
  parseISO,
  setHours,
  setMinutes,
  startOfToday,
} from "date-fns";
import { BASE_API_URL } from "../../../utils/consts";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import type { InputRef } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./AddAppointmentModal.scss";
import { useDispatch } from "react-redux";
import { addPatient } from "../../../utils/redux/slices/patientsSlice";
// import { toZonedTime, formatInTimeZone } from "date-fns-tz";
import { toDate, toZonedTime } from "date-fns-tz";

const { Option } = Select;

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  staffMembers: any;
  patients: any;
  selectedDate?: any;
  userData: any;
  mode?: any;
  modalData?: any;
  authUser?: any;
};

enum ScheduleType {
  APPOINTMENT = "APPOINTMENT",
  EVENT = "EVENT",
}

const AddApointmentModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  staffMembers,
  patients,
  selectedDate,
  mode = "create",
  userData,
  modalData,
  authUser,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeStaffMember, setActiveStaffMember] = useState<any>("");
  const [activePatient, setActivePatient] = useState<any>("");
  const [form] = Form.useForm();
  const [activeType, setActiveType] = useState<any>(ScheduleType.APPOINTMENT);
  const [newClientName, setNewClientName] = useState("");
  const [openPatient, setOpen] = useState(false);

  const selectRef: any = useRef();
  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    // Set form initial values when date changes
    if (mode !== "edit") {
      if (selectedDate) {
        form.setFieldsValue({
          date: selectedDate,
          startTime: dayjs(selectedDate),
          endTime: dayjs(addHours(new Date(selectedDate), 1)),
        });
      } else {
        // Reset form fields if date is null or undefined
        form.resetFields();
      }
    } else {
      setActiveType(modalData.type as ScheduleType);
      form.setFieldsValue({
        date: dayjs(modalData.start),
        startTime: dayjs(modalData.start),
        endTime: dayjs(modalData.end),
        user: modalData.user._id,
        patient: modalData?.patient?._id,
        type: modalData.type,
        injury: modalData.injury,
      });
    }
  }, [selectedDate, form, mode]);
  const handleOk = () => {
    onCloseModal();
    form.resetFields();
  };

  const handleCancel = () => {
    onCloseModal();
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    const staffMember = await staffMembers.find(
      (item: any) => item._id === values.user
    );
    const patientMember = await patients.find(
      (item: any) => item._id === values.patient
    );
    const startHours = getHours(new Date(values.startTime));
    const endHours = getHours(new Date(values.endTime));
    const startMinutes = getMinutes(new Date(values.startTime));
    const endMinutes = getMinutes(new Date(values.endTime));
    const updatedDateStart = setHours(
      setMinutes(new Date(values.date), startMinutes),
      startHours
    );
    const updatedDateEnd = setHours(
      setMinutes(new Date(values.date), endMinutes),
      endHours
    );
    const localDate = toDate(new Date(updatedDateStart), {
      timeZone: "Europe/Berlin",
    });
    const localDateEndTime = toDate(new Date(updatedDateEnd), {
      timeZone: "Europe/Berlin",
    });

    // Handle UTC conversion manually without changing hours and minutes
    const utcTime = new Date(
      Date.UTC(
        localDate.getUTCFullYear(),
        localDate.getUTCMonth(),
        localDate.getUTCDate(),
        localDate.getUTCHours(),
        localDate.getUTCMinutes(),
        localDate.getUTCSeconds(),
        localDate.getUTCMilliseconds()
      )
    );
    const utcEndTime = new Date(
      Date.UTC(
        localDateEndTime.getUTCFullYear(),
        localDateEndTime.getUTCMonth(),
        localDateEndTime.getUTCDate(),
        localDateEndTime.getUTCHours(),
        localDateEndTime.getUTCMinutes(),
        localDateEndTime.getUTCSeconds(),
        localDateEndTime.getUTCMilliseconds()
      )
    );

    // Format the UTC date as desired
    const formattedUTCDateStartTime = format(
      utcTime,
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );
    const formattedUTCDateEndTime = format(
      utcEndTime,
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );
    console.log("formattedUTCDateStartTime", formattedUTCDateStartTime);
    console.log("formattedUTCDateEndTime", formattedUTCDateEndTime);
    if (mode === "create") {
      if (activeType === ScheduleType.APPOINTMENT) {
        axios
          .post(`${BASE_API_URL}/appointment/create`, {
            userId: staffMember._id,
            patientId: patientMember._id,
            clientId: userData.clientId,
            date: values.date,
            startTime: formattedUTCDateStartTime,
            endTime: formattedUTCDateEndTime,
            injury: values.injury,
            type: ScheduleType.APPOINTMENT,
          })
          .then(function (response) {
            // `${getHours(new Date(values.time[1]))}:${getMinutes(
            // 	new Date(values.time[1])
            //   )}`
            // handle success
            if (selectedDate) {
              navigate("/schedules");
            } else {
              navigate("/appointments");
            }
            form.resetFields();
            onCloseModal();
          })
          .catch(function (error) {
            // handle error
            console.error(error);
          });
      } else {
        axios
          .post(`${BASE_API_URL}/event/create`, {
            userId: staffMember._id,
            organizerId: authUser.id,
            clientId: userData.clientId,
            date: values.date,
            startTime: formattedUTCDateStartTime,
            endTime: formattedUTCDateEndTime,
            description: values.injury,
            type: ScheduleType.EVENT,
          })
          .then(function (response) {
            // `${getHours(new Date(values.time[1]))}:${getMinutes(
            // 	new Date(values.time[1])
            //   )}`
            // handle success
            // if (selectedDate) {
            //   navigate("/schedules");
            // } else {
            //   navigate("/appointments");
            // }
            form.resetFields();
            onCloseModal();
          })
          .catch(function (error) {
            // handle error
            console.error(error);
          });
      }
    } else {
      const currentDate = new Date();
      if (activeType === ScheduleType.APPOINTMENT) {
        axios
          .put(`${BASE_API_URL}/appointment/${modalData.appointmentId}`, {
            userId: values.user,
            patientId: values.patient,
            clientId: modalData.user.clientId,
            date: values.date,
            startTime: formattedUTCDateStartTime,
            endTime: formattedUTCDateEndTime,
            injury: values.injury,
            type: ScheduleType.APPOINTMENT,
          })
          .then(function (response) {
            // `${getHours(new Date(values.time[1]))}:${getMinutes(
            // 	new Date(values.time[1])
            //   )}`
            // handle success
            if (selectedDate) {
              navigate("/schedules");
            } else {
              navigate("/appointments");
            }
            form.resetFields();
            onCloseModal();
          })
          .catch(function (error) {
            // handle error
            console.error(error);
          });
      } else {
        axios
          .put(`${BASE_API_URL}/event/${modalData.appointmentId}`, {
            userId: values.user,
            patientId: values.patient,
            clientId: modalData.user.clientId,
            date: values.date,
            startTime: formattedUTCDateStartTime,
            endTime: formattedUTCDateEndTime,
            injury: values.injury,
            type: ScheduleType.EVENT,
          })
          .then(function (response) {
            // `${getHours(new Date(values.time[1]))}:${getMinutes(
            // 	new Date(values.time[1])
            //   )}`
            // handle success
            if (selectedDate) {
              navigate("/schedules");
            } else {
              navigate("/appointments");
            }
            form.resetFields();
            onCloseModal();
          })
          .catch(function (error) {
            // handle error
            console.error(error);
          });
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  const onGenderChange = (value: string) => {
    setActiveStaffMember(value);
  };

  const onPatientChange = (value: string) => {
    setActivePatient(value);
  };

  const onChangeType = (value: any) => {
    setActiveType(value);
  };
  const onChangeStartTime = (time: any) => {
    const currentValues = form.getFieldsValue();
    const endHours = addHours(new Date(time), 1);
    const updatedValues = {
      ...currentValues,
      startTime: time,
      endTime: dayjs(new Date(endHours)),
    };
    form.setFieldsValue(updatedValues);
  };
  const onChangeEndTime = (time: any) => {
    const currentValues = form.getFieldsValue();
    const updatedValues = {
      ...currentValues,
      endTime: time,
    };
    form.setFieldsValue(updatedValues);
  };
  const validateEndTime = (_: any, value: any) => {
    const startTime = form.getFieldValue("startTime");
    // Check if end time is less than start time
    if (isBefore(new Date(value), new Date(startTime))) {
      return Promise.reject(t("End time must be later than start time"));
    }

    return Promise.resolve();
  };

  const deleteEvent = () => {
    if (modalData.type === ScheduleType.EVENT) {
      axios
        .delete(`${BASE_API_URL}/event/${modalData.appointmentId}`)
        .then((res) => {
          handleCancel();
        })
        .catch((error) => console.error(error));
    } else {
      axios
        .delete(`${BASE_API_URL}/appointment/${modalData.appointmentId}`)
        .then((res) => {
          handleCancel();
        })
        .catch((error) => console.error(error));
    }
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewClientName(event.target.value);
  };

  const addNewPatient = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
    axios
      .post(`${BASE_API_URL}/patient/create`, {
        userId: authUser.id,
        clientId: authUser?.client?._id,
        name: newClientName,
        // costs: req.body.costs,
      })
      .then(function (response: any) {
        dispatch(addPatient(response.data.patient));
        setActivePatient(response.data.patient._id);
        selectRef.current.focus();
        setOpen(false);
        setNewClientName("");
        form.setFieldsValue({
          patient: response.data.patient._id,
        });
        // navigate("/patients");
        // onCloseModal();
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const disabledPast = (current: any) => {
    return current && isBefore(current.toDate(), startOfToday());
  };
  return (
    <Modal
      title={
        <div
          className="appointmentModalHeader"
          style={{ background: modalData?.user?.color ?? "orange" }}
        >
          {t("Appointment")}
        </div>
      }
      open={isOpen}
      onOk={handleOk}
      className="appointmentModal"
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* if (mode === "create")  */}
        {mode === "create" && (
          <Form.Item
            name="type"
            label={t("Type")}
            rules={[{ required: true, message: t("Please select type!") }]}
          >
            <Select
              placeholder={t("Please select type")}
              onChange={onChangeType}
              value={activeType}
              // showSearch
              // filterOption={(input: any, option: any) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
            >
              <Option value={ScheduleType.APPOINTMENT} key={"appointment"}>
                {t("Patient Appointment")}
              </Option>
              <Option value={ScheduleType.EVENT} key={"event"}>
                {t("Event")}
              </Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="user"
          label={t("Doctor")}
          rules={[{ required: true, message: t("Please select doctor!") }]}
        >
          <Select
            placeholder={t("Please select doctor!")}
            onChange={onGenderChange}
            //   allowClear
            value={activeStaffMember}
            showSearch
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {staffMembers?.map((item: any) => (
              <Option value={item._id} key={item._id}>
                {item.username}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {activeType === ScheduleType.APPOINTMENT && (
          <Form.Item
            name="patient"
            label={t("Patient")}
            rules={[{ required: true, message: t("Please select patient!") }]}
          >
            <Select
              placeholder={t("Please select patient!")}
              onChange={onPatientChange}
              value={activePatient}
              ref={selectRef}
              open={openPatient}
              onDropdownVisibleChange={(visible) => setOpen(visible)}
              showSearch
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Please enter name"
                      ref={inputRef}
                      value={newClientName}
                      onChange={onNameChange}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={addNewPatient}
                    >
                      {t("Add Patient")}
                    </Button>
                  </Space>
                </>
              )}
            >
              {patients?.map((item: any) => (
                <Option value={item._id} key={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="date"
          label={t("Date")}
          rules={[{ required: true, message: t("Please select date!") }]}
        >
          <DatePicker disabledDate={disabledPast} />
        </Form.Item>
        <Form.Item
          name="startTime"
          label={t("Start Time")}
          rules={[{ required: true, message: t("Please select time!") }]}
        >
          <TimePicker
            onSelect={(time) => {
              console.log("time", time);
              onChangeStartTime(time);
            }}
            format="HH:mm"
            minuteStep={15}
          />
        </Form.Item>
        <Form.Item
          name="endTime"
          label={t("End Time")}
          rules={[
            { required: true, message: t("Please select time!") },
            { validator: validateEndTime },
          ]}
        >
          <TimePicker
            onSelect={(time) => onChangeEndTime(time)}
            format="HH:mm"
            minuteStep={15}
          />
        </Form.Item>
        {/* <Form.Item
          name="time"
          label={t("Time")}
          rules={[{ required: true, message: "Please select time!" }]}
        >
          <TimePicker.RangePicker minuteStep={15} format="HH:mm" />
        </Form.Item> */}
        <Form.Item label={t("Description")} name="injury">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Flex gap="small" wrap="wrap">
            <Button type="primary" htmlType="submit">
              {t("Save")}
            </Button>
            {mode !== "create" && (
              <Button danger onClick={() => deleteEvent()}>
                {t("Delete")}
              </Button>
            )}
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddApointmentModal;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_API_URL } from "../../utils/consts";
import { Button } from "antd";
import "./ActiveAccount.scss";
type Props = {};
const ActiveAccount: React.FC<Props> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<any>(false);
  useEffect(() => {
    localStorage.clear();
  }, []);
  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/activate/${params.token}`)
      .then((res) => {
        console.log("res", res);
        navigate("/");
      })
      .catch((err) => {
        setError(true);
      });
  }, [params]);
  console.log("params", params);
  return error ? (
    <div className="tokenInvalid">
      <p>Token invalid, go back to login page</p>
      <Button onClick={() => navigate("/")}>Login</Button>
    </div>
  ) : (
    <div className="tokenInvalid">
      <p>Account activated </p>
      <Button onClick={() => navigate("/")}>Login</Button>
    </div>
  );
};

export default ActiveAccount;

import React, { useEffect, useState } from "react";
import { ColorPicker, DatePicker, InputNumber, Modal, Select } from "antd";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../utils/useAuth";
import { isAdmin } from "../../../utils/permissions";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  userDetails: any;
  onUpdateProfile: (profileData: any) => void;
  locations: any;
};

const { Option } = Select;

const UserProfileModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  onUpdateProfile,
  userDetails,
  locations,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()
  const [color, setColor] = useState<any>("#ff0000");
  const authUser: any = useAuth();
  const [activeLocation, setActiveLocation] = useState<any>(undefined);

  const [activeWorkingPattern, setActiveWorkingPattern] =
    useState<any>(undefined);
  const workingPatterns = useSelector(
    (state: any) => state.workingPattern.contracts
  );
  const clientLeaves = useSelector((state: any) => state.clientLeaves);

  useEffect(() => {
    // Set form initial values when activeNotes changes

    if (userDetails) {
      const clientLeaveIDs = userDetails?.user?.clientLeaves?.map(
        (item: any) => item._id
      );
      setActiveWorkingPattern({
        key: userDetails?.user?.workingPatternId?._id,
        value: userDetails?.user?.workingPatternId?._id,
        children: userDetails?.user?.workingPatternId?.contractName,
      });
      console.log(
        "userDetails?.user?.contractStart",
        userDetails?.user?.contractStart
      );
      form.setFieldsValue({
        firstName: userDetails?.user?.firstName,
        lastName: userDetails?.user?.lastName,
        position: userDetails?.user?.position,
        gender: userDetails?.user?.gender,
        amount: userDetails?.user?.amount,
        workingPatternId: userDetails?.user?.workingPatternId?._id,
        location: userDetails?.user?.location?._id,
        clientLeaves: clientLeaveIDs,
        contractStart: userDetails?.user?.contractStart
          ? dayjs(userDetails?.user?.contractStart)
          : null,
      });
      setColor(userDetails?.user?.color);
    } else {
      form.resetFields();
    }
    // Reset form fields if activeNotes is null or undefined
  }, [userDetails, form]);

  const handleOk = () => {
    onCloseModal();
  };
  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    if (color) {
      onUpdateProfile({ ...values, color: color });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  const renderWorkingPatternTitle = () => {
    const findActiveWorkingPattern = workingPatterns?.find(
      (item: any) => item._id === activeWorkingPattern?.value
    );
    if (findActiveWorkingPattern?.type === "PERCENT") {
      return t("Earnings in Percentage");
    } else if (findActiveWorkingPattern?.type === "FIXED") {
      return t("Monthly salary");
    } else if (findActiveWorkingPattern?.type === "HOURLY") {
      return t("Hourly rate");
    }
  };
  return (
    <Modal
      title={t("Update user profile")}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        //   initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t("First Name")}
          name="firstName"
          rules={[{ required: true, message: t("First Name Required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Last Name")}
          name="lastName"
          rules={[{ required: true, message: t("Last Name Required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="position"
          label={t("Position Type")}
          rules={[{ required: true, message: "Please select position!" }]}
        >
          <Select
            placeholder={t("Select position type")}
            // onChange={onGenderChange}
            //   allowClear
            value={""}
          >
            <Option value={"DOCTOR"} key={"doctor"}>
              {t("Doctor")}
            </Option>
            <Option value={"NURSE"} key={"nurse"}>
              {t("Nurse")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="gender"
          label={t("Gender")}
          rules={[{ required: true, message: "Please select" }]}
        >
          <Select
            placeholder={t("Please select")}
            // onChange={onGenderChange}
            //   allowClear
            value={""}
          >
            <Option value={"male"} key={"male"}>
              {t("Male")}
            </Option>
            <Option value={"female"} key={"female"}>
              {t("Female")}
            </Option>
          </Select>
        </Form.Item>
        {isAdmin(authUser?.roles) && (
          <Form.Item
            name="workingPatternId"
            label={t("Working Pattern")}
            rules={[{ required: true, message: "Please select position!" }]}
          >
            <Select
              placeholder={t("Select working pattern type")}
              onChange={(value, data) => setActiveWorkingPattern(data)}
              //   allowClear
              value={""}
            >
              {workingPatterns?.map((item: any, index: number) => {
                return (
                  <Option value={item._id} key={index}>
                    {item.contractName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        {isAdmin(authUser?.roles) && (
          <Form.Item
            name="clientLeaves"
            label={t("User Leaves")}
            rules={[{ required: false, message: "Please select leaves type!" }]}
          >
            <Select
              mode="multiple"
              placeholder={t("Select leaves type")}
              // onChange={onGenderChange}
              showSearch // Add this prop to enable search
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              } // Add custom filter function for case-insensitive search
              allowClear
            >
              {clientLeaves?.clientLeaves?.map((item: any, index: number) => {
                return (
                  <Option value={item._id} key={index}>
                    {item.leaveName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="location"
          label={t("Location ")}
          rules={[{ required: true, message: "Please select location!" }]}
        >
          <Select
            placeholder={t("Select location  ")}
            onChange={(value, data) => setActiveLocation(data)}
            //   allowClear
            value={""}
          >
            {locations?.map((item: any, index: number) => {
              return (
                <Option value={item._id} key={index}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {/* <Select
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder="Please select"
      defaultValue={['a10', 'c12']}
      onChange={handleChange}
      options={options}
    /> */}
        {isAdmin(authUser?.roles) && (
          <Form.Item
            label={renderWorkingPatternTitle()}
            name="amount"
            rules={[{ required: false, message: t("Last Name Required") }]}
          >
            <InputNumber defaultValue={30} min={0} max={1000000} />
          </Form.Item>
        )}
        {isAdmin(authUser?.roles) && (
          <Form.Item
            name="contractStart"
            label={t("Contract start date")}
            rules={[
              {
                required: true,
                message: t("Please enter contract start date"),
              },
            ]}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
        )}
        <Form.Item name="profileColor" label={t("Profile Color")}>
          <ColorPicker
            defaultValue={userDetails?.user?.color}
            onChange={(value, hexColor) => setColor(hexColor)}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserProfileModal;

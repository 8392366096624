import { Button, Dropdown, MenuProps, Space } from "antd";
import React from "react";
import { GlobalOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./SelectLanguage.css";
type Props = {};
const SelectLanguage: React.FC<Props> = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const userLanguage = localStorage.getItem("userLanguage");
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };
  const items: MenuProps["items"] = [
    {
      label: (
        <Button
          className="languageOption"
          onClick={() => {
            changeLanguage("en");
            localStorage.setItem("userLanguage", "en");
          }}
        >
          {t("English")}
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          className="languageOption"
          onClick={() => {
            changeLanguage("sr");
            localStorage.setItem("userLanguage", "sr");
          }}
        >
          {t("Serbian")}
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <Button
          className="languageOption"
          onClick={() => {
            changeLanguage("de");
            localStorage.setItem("userLanguage", "de");
          }}
        >
          {t("German")}
        </Button>
      ),
      key: "2",
    },
  ];

  return (
    <Dropdown className="selectLanguage" menu={{ items }} trigger={["click"]}>
      <div>
        <GlobalOutlined />
        <Space>{userLanguage ?? "EN"}</Space>
      </div>
    </Dropdown>
  );
};

export default SelectLanguage;

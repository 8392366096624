

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState: any = {
	// Events Metadata
	isLoaderActive: false
};

export const loaderSlice = createSlice({
  name: 'loaderSlice',
  initialState,
  reducers: {
    setLoader(state: any, action: PayloadAction<any>) {
		state.isLoaderActive = action.payload;
	},
  }
})

// Action creators are generated for each case reducer function
export const { setLoader } = loaderSlice.actions

export default loaderSlice.reducer
import { Button, Space, Tag } from "antd";
import React, { useEffect, useState } from "react";
import UserLeavesModal from "../../../../Components/Modals/UserLeavesModal/UserLeavesModal";
import axios from "axios";
import { BASE_API_URL } from "../../../../utils/consts";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import Table, { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";
import UserObjectiveModal from "../../../../Components/Modals/UserObjectiveModal/UserObjectiveModal";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
type Props = {
  userDetails: any;
};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const UserObjectives: React.FC<Props> = ({ userDetails }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [objectives, setObjectives] = useState<any>(undefined);
  const [leaveModalOpen, setLeaveModalOpen] = useState<any>(false);

  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/${params.id}/user_details_objective`)
      .then((res) => {
        setObjectives(res.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const onDeleteLeave = (leaveId: any) => {
    axios
      .delete(`${BASE_API_URL}/${leaveId}/objective_delete`)
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/${params.id}/user_details_objective`)
          .then((res) => {
            setObjectives(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const approveObjective = (status: any, id: any) => {
    axios
      .put(`${BASE_API_URL}/objective/${id}/objective_update`, {
        status: status,
      })
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/${params.id}/user_details_objective`)
          .then((res) => {
            setObjectives(res.data);
          })
          .catch((error) => console.error(error));
      })
      .catch((err) => console.error(err));
  };
  const columns: ColumnsType<DataType> = [
    {
      title: t("Name"),
      dataIndex: "userId",
      key: "name",
      render: (row) => row?.username,
    },
    {
      title: t("Do to"),
      dataIndex: "deadline",
      key: "deadline",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (value, status) => {
        const color =
          value === "COMPLETED"
            ? "green"
            : value === "INCOMPLETED"
            ? "orange"
            : "red";
        return (
          <>
            <Tag color={color} key={value}>
              {value}
            </Tag>
          </>
        );
      },
    },

    {
      title: "",
      key: "action",
      render: (row, record: any) => (
        <>
          <Space size="middle">
            <Button onClick={() => approveObjective("COMPLETED", record._id)}>
              <CheckOutlined />
            </Button>
            <Button onClick={() => approveObjective("INCOMPLETED", record._id)}>
              <CloseOutlined />
            </Button>
            {/* <Button onClick={() => onDeleteLeave(record._id)}>
              {t("Delete")}
            </Button> */}
          </Space>
        </>
      ),
    },
  ];

  const onAddObjective = (objective: any) => {
    axios
      .post(`${BASE_API_URL}/objective/create`, {
        description: objective.description,
        type: objective.type,
        deadline: objective.deadline,
        userId: userDetails?.user?._id,
        clientId: userDetails?.user?.clientId?._id,
      })
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/${params.id}/user_details_objective`)
          .then((res) => {
            setObjectives(res.data);
          })
          .catch((err) => console.error(err));
        setLeaveModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <Button onClick={() => setLeaveModalOpen(true)}>
        {t("Request Objective")}
      </Button>
      <Table columns={columns} dataSource={objectives} />
      <UserObjectiveModal
        isOpen={leaveModalOpen}
        onCloseModal={() => setLeaveModalOpen(false)}
        onAddLeave={onAddObjective}
      />
    </>
  );
};

export default UserObjectives;

import React, { useEffect, useMemo, useRef, useState } from "react";
import type { Dayjs } from "dayjs";
import type { BadgeProps, CalendarProps } from "antd";
import { Badge, Calendar } from "antd";
import dayjs from "dayjs";

import {
  endOfWeek,
  format,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  startOfWeek,
  subDays,
} from "date-fns";
import axios from "axios";
import "./Schedules.css";
import { BASE_API_URL } from "../../utils/consts";
import AddApointmentModal from "../../Components/Modals/AddApointmentModal/AddApointmentModal";
import { useSelector } from "react-redux";
import CalendarWeek from "./cal";
import { useAuth } from "../../utils/useAuth";

const getMonthData = (value: Dayjs) => {
  if (value.month() === 8) {
    return 1394;
  }
};

const Schedules: React.FC = () => {
  const [appointments, setAppointments] = useState<any>([]);
  const [events, setEvents] = useState<any>([]);
  const patients = useSelector((state: any) => state.patients.patients);
  const [activeDate, setActiveDate] = useState<any>(undefined);
  const [staffMembers, setStaffMembers] = useState<any>(undefined);
  const [selectedDate, setSelectedDate] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);
  const [modalData, setModalData] = useState<any>(undefined);
  const [mode, setMode] = useState<any>("create");
  const authUser: any = useAuth();
  const [showAppointmentModal, setShowAppointmentModal] =
    useState<boolean>(false);

  const startDate: any = activeDate
    ? startOfWeek(new Date(activeDate))
    : startOfWeek(new Date()); // Replace with the desired start date
  const endDate: any = activeDate
    ? endOfWeek(new Date(activeDate))
    : endOfWeek(new Date()); // Replace with the desired end date
  useEffect(() => {
    if (userData.clientId) {
      axios
        .get(
          `${BASE_API_URL}/${
            userData.clientId
          }/appointments?startDate=${encodeURIComponent(
            startDate
          )}&endDate=${encodeURIComponent(endDate)}`
        )
        .then((res: any) => {
          setAppointments(res.data);
        })
        .catch((error) => console.error(error));
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/events`)
        .then((res: any) => {
          setEvents(res.data);
        })
        .catch((error) => console.error(error));
    }
  }, [userData, activeDate]);

  useEffect(() => {
    if (userData.clientId) {
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/users`)
        .then((res) => {
          setStaffMembers(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [userData]);

  const getListData = (value: any) => {
    const usersByDate: any = {};

    appointments?.forEach((appointment: any) => {
      const date = appointment.date.split("T")[0]; // Extracting the date part
      if (!usersByDate[date]) {
        usersByDate[date] = [];
      }
      usersByDate[date].push(appointment);
    });

    // Check if the target date exists in the data
    if (usersByDate.hasOwnProperty(format(new Date(value), "yyyy-MM-dd"))) {
      // Retrieve the object value for the specified date
      const appointmentsForDate =
        usersByDate[format(new Date(value), "yyyy-MM-dd")];

      // Now, appointmentsForDate contains an array of objects for the specified date
      const listData = appointmentsForDate.map((item: any) => {
        return {
          type: "warning",
          content: (
            <span>
              <b>
                ({item?.startTime} - {item?.endTime})<br />
              </b>
              {item?.patientId?.name}{" "}
            </span>
          ),
          item: item,
        };
      });
      return listData;
    } else {
      // console.log(`No appointments found for the date: ${format(new Date(value), 'yyyy-MM-dd')}`);
      return [];
    }
  };
  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item: any) => (
          <li key={item.content} onClick={() => console.log("click", item)}>
            <Badge
              status={item.type as BadgeProps["status"]}
              color={item?.item?.userId?.color ?? "orange"}
              text={item.content}
            />
          </li>
        ))}
      </ul>
    );
  };

  const showModal = () => {
    setShowAppointmentModal(!showAppointmentModal);
    if (userData) {
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/appointments?startDate=${encodeURIComponent(
            startDate
          )}&endDate=${encodeURIComponent(endDate)}`)
        .then((res) => {
          setAppointments(res.data);
        })
        .catch((err) => console.error(err));
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/events`)
        .then((res: any) => {
          setEvents(res.data);
        })
        .catch((error) => console.error(error));
    }
  };

  
  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  const openModal = (data: any, mode: any) => {
    console.log("data", data);
    console.log("mode", mode);
    setSelectedDate(dayjs(new Date(data.start)));
    setMode(mode);
    setModalData(data);
    setShowAppointmentModal(!showAppointmentModal);
  };

  const onUpdateDate = (data: any) => {
    const startHours = getHours(new Date(data.start));
    const endHours = getHours(new Date(data.end));
    const startMinutes = getMinutes(new Date(data.start));
    const endMinutes = getMinutes(new Date(data.end));
    const updatedDateStart = setHours(
      setMinutes(new Date(data.start), startMinutes),
      startHours + 1
    );
    const updatedDateEnd = setHours(
      setMinutes(new Date(data.end), endMinutes),
      endHours + 1
    );
    axios
      .put(`${BASE_API_URL}/appointment/${data?.appointmentId}`, {
        startTime: updatedDateStart,
        endTime: updatedDateEnd,
        date: data.start,
      })
      .then(function (response) {
        if (userData) {
          axios
            .get(`${BASE_API_URL}/${userData.clientId}/appointments`)
            .then((res) => {
              setAppointments(res.data);
            })
            .catch((err) => console.error(err));
          axios
            .get(`${BASE_API_URL}/${userData.clientId}/events`)
            .then((res: any) => {
              setEvents(res.data);
            })
            .catch((error) => console.error(error));
        }
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
  };

  const renderCalendarList = useMemo(() => {
    return [...appointments, ...events];
  }, [appointments, events]);

  const changeActiveDate = (date: any) => {
    setActiveDate(date);
  };

  console.log('renderCalendarList', renderCalendarList)
  return (
    <div className="mainContainer">
      {/* <Calendar
        cellRender={cellRender}
        onSelect={(date, { source }) => {
          if (source === "date") {
            setSelectedDate(date);
            setShowAppointmentModal(!showAppointmentModal);
          }
        }}
      /> */}
      {/* <DayPilotCalendar {...state} ref={calendarRef} /> */}
      {appointments && (
        <CalendarWeek
          appointments={renderCalendarList}
          openModal={openModal}
          onUpdateDate={onUpdateDate}
          activeDate={(date: any) => changeActiveDate(date)}
        />
      )}

      {showAppointmentModal && (
        <AddApointmentModal
          isOpen={showAppointmentModal}
          selectedDate={selectedDate}
          onCloseModal={showModal}
          patients={patients}
          staffMembers={staffMembers}
          userData={userData}
          mode={mode}
          modalData={modalData}
          authUser={authUser}
        />
      )}
    </div>
  );
};

export default Schedules;

import React, { useState } from "react";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getHours, getMinutes } from "date-fns";
import { BASE_API_URL } from "../../../utils/consts";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addPatient } from "../../../utils/redux/slices/patientsSlice";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
};

const AddPatientModal: React.FC<Props> = ({ isOpen, onCloseModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStaffMember, setActiveStaffMember] = useState<any>("");
  const [activePatient, setActivePatient] = useState<any>("");
  const userDetails: any = JSON.parse(localStorage.getItem("user") ?? "");
  const handleOk = () => {
    onCloseModal();
  };

  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    axios
      .post(`${BASE_API_URL}/patient/create`, {
        userId: userDetails.id,
        clientId: userDetails.clientId,
        name: values.name,
        email: values.email,
        phoneNo: values.phoneNo,
        gender: values.gender,
        birthDay: values.birthDay,
        jmbg: values.jmbg,
        address: values.address,
        specialNotes: values.specialNotes,
        // costs: req.body.costs,
      })
      .then(function (response: any) {
        dispatch(addPatient(response.data.patient));
        // navigate("/patients");
        onCloseModal();
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  const onGenderChange = (value: string) => {
    setActiveStaffMember(value);
  };

  const onPatientChange = (value: string) => {
    setActivePatient(value);
  };
  return (
    <Modal
      title={t("Add Patient")}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t("Name")}
          name="name"
          rules={[{ required: true, message: t("Please enter name") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: t("Please enter email") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Phone Number")}
          name="phoneNo"
          rules={[{ required: true, message: t("Please enter phone number") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="gender"
          label={t("Gender")}
          rules={[{ required: true, message: t("Please select gender") }]}
        >
          <Select placeholder={t("Select gender")}>
            <Option value="male">{t("Male")}</Option>
            <Option value="female">{t("Female")}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="birthDay"
          label={t("Birthday")}
          rules={[{ required: true, message: t("Please enter birth date") }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label={t("Social Number")}
          name="jmbg"
          rules={[
            { required: false, message: t("Please enter social number") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("Address")} name="address">
          <Input />
        </Form.Item>
        <Form.Item label={t("Special Notes")} name="specialNotes">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPatientModal;

import React, { useEffect, useMemo, useTransition } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";
import axios from "axios";
import AddPatientModal from "../../Components/Modals/AddPatientModal/AddPatientModal";
import {
  differenceInMonths,
  format,
  formatDistanceToNow,
  max,
  parseISO,
} from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  MessageOutlined,
  DeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import "./Patients.scss";
import { useTranslation } from "react-i18next";
import { BASE_API_URL } from "../../utils/consts";
import { useDispatch, useSelector } from "react-redux";
import { deletePatient } from "../../utils/redux/slices/patientsSlice";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
const { enUS, srLatn } = require("date-fns/locale");

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

type Props = {};

const Patients: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language: any = localStorage.getItem("userLanguage") ?? "en";
  const locale = language === "en" ? enUS : srLatn;
  const [searchPatients, setSearchPatients] = useState<any>("");
  const [patientModalOpen, setPatientModalOpen] = useState<boolean>(false);
  const patients = useSelector((state: any) => state.patients.patients);

  const deleteItem = (row: any) => {
    dispatch(setLoader(true));
    axios
      .delete(`${BASE_API_URL}/patient/${row._id}`)
      .then((res) => {
        dispatch(deletePatient(res.data.id));
      })
      .catch((error) => console.error(error))
      .finally(() => dispatch(setLoader(false)));
  };

  const openProfile = (row: any) => {
    navigate(`${row._id}`);
  };
  const columns: ColumnsType<DataType> = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      render: (text) => text,
    },
    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: t("Social Number"),
    //   dataIndex: "jmbg",
    //   key: "jmbg",
    //   render: () => "********",
    // },
    {
      title: t("Phone Number"),
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      title: t("Birthday"),
      dataIndex: "birthDay",
      key: "birthDay",
      render: (value, row) => {
        return value
          ? format(new Date(value ? value : new Date()), "d-MMM-yyyy")
          : "-";
      },
    },
    {
      title: t("Last Control"),
      dataIndex: "lastActiveNotes",
      key: "lastActiveNotes",
      sorter: (a: any, b: any) => {
        const latestDateA: any =
          a.lastActiveNotes?.length > 0
            ? new Date(a.lastActiveNotes[0].date)
            : null;
        const latestDateB: any =
          b.lastActiveNotes?.length > 0
            ? new Date(b.lastActiveNotes[0].date)
            : null;

        if (latestDateA && latestDateB) {
          return latestDateB - latestDateA;
        } else if (latestDateA) {
          return 1;
        } else if (latestDateB) {
          return -1;
        } else {
          return 0;
        }
      },
      render: (value, row) => {
        let dates: any = value?.length
          ? value.map((item: any) => parseISO(item?.date))
          : undefined;
        const latestDate: any = dates && max(dates);
        const formattedDistance =
          latestDate &&
          formatDistanceToNow(latestDate, {
            addSuffix: true,
            locale,
          });
        const monthsDifference = differenceInMonths(
          new Date(),
          latestDate ?? new Date()
        );
        if (monthsDifference > 5) {
          return (
            <Tag color={"#3399cc"} key={"patientWarning"}>
              <WarningOutlined twoToneColor="#ff0000" />{" "}
              {formattedDistance?.toUpperCase() ?? ""}
            </Tag>
          );
        } else if (monthsDifference > 4) {
          return (
            <Tag color={"orange"} key={"patientWarning"}>
              <WarningOutlined twoToneColor="#ff0000" />{" "}
              {formattedDistance?.toUpperCase() ?? ""}
            </Tag>
          );
        } else {
          return (
            formattedDistance && (
              <Tag color={"green"} key={"patientWarning"}>
                <WarningOutlined twoToneColor="#ff0000" />{" "}
                {formattedDistance?.toUpperCase() ?? ""}
              </Tag>
            )
          );
        }
      },
    },
    {
      title: t("Photo"),
      dataIndex: "file",
      key: "phoneNo",
      render: (file) =>
        file && file[0] ? (
          <a target="_blank" rel="noreferrer" href={file[0]}>
            Open photo
          </a>
        ) : (
          ""
        ),
    },
    {
      title: "",
      key: "action",
      render: (row, record: any) => {
        let dates: any = record.lastActiveNotes?.length
          ? record.lastActiveNotes.map((item: any) => parseISO(item.date))
          : undefined;
        const latestDate: any = dates && max(dates);
        const formattedDistance =
          latestDate &&
          formatDistanceToNow(latestDate, {
            addSuffix: true,
            locale,
          });
        const monthsDifference = differenceInMonths(
          new Date(),
          latestDate ?? new Date()
        );
        return (
          <Space>
            <Space size="middle">
              <Button onClick={() => openProfile(row)}>
                {t("User Profile")}
              </Button>
            </Space>
            <Button disabled={monthsDifference < 6}>
              <MessageOutlined />
            </Button>
            <Button onClick={() => deleteItem(row)}>
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  const showModal = () => {
    setPatientModalOpen(!patientModalOpen);
    // axios
    //   .get(`${BASE_API_URL}/patient/all`)
    //   .then((res) => {
    //     // TODO UpdatePatientsList
    //     // setPatients(res.data);
    //   })
    //   .catch((err) => console.error(err));
  };

  const onSearchPatients = (e: any) => {
    setSearchPatients(e.target.value);
  };

  const renderPatients = useMemo(() => {
    if (searchPatients) {
      const filterPatients = patients?.filter((item: any) =>
        item.name.toLowerCase().includes(searchPatients.toLowerCase())
      );
      return filterPatients;
    } else {
      return patients;
    }
  }, [searchPatients, patients]);
  return (
    <div className="mainContainer">
      <Row justify={"space-between"}>
        <Col>
          <Button type="primary" onClick={showModal}>
            + {t("Add Patient")}
          </Button>
        </Col>
        <Col>
          <div className="searchPatients">
            <Form.Item label={t("Search Patients")} name="description">
              <Input
                placeholder={t("Search")}
                onChange={(e: any) => onSearchPatients(e)}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Divider />
      <div className="hide-md">
        <Table
          columns={columns}
          dataSource={renderPatients?.length ? renderPatients : []}
        />
      </div>
      <div className="show-md patientsFullList">
        {renderPatients?.length &&
          renderPatients?.map((item: any, index: number) => {
            return (
              <div className="patientsListItem" key={index}>
                <b>
                  {t("Patient Name")}:{item?.name}
                </b>
                <div>{item?.phoneNo}</div>
                <div>
                  {t("Birth Date")}:
                  {item?.birthDay ??
                    format(new Date(item?.birthDay ?? new Date()), "d-MMM-yy")}
                </div>
              </div>
            );
          })}
      </div>
      <AddPatientModal isOpen={patientModalOpen} onCloseModal={showModal} />
    </div>
  );
};

export default Patients;

import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getHours, getMinutes } from "date-fns";
import { BASE_API_URL } from "../../../utils/consts";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  notes: any;
  activeNotes?: any;
  isNotesUsed?: any;
  patient?: any;
  staffMembers: any;
};

const NotesModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  notes,
  activeNotes,
  isNotesUsed,
  staffMembers,
  patient,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const userData = useSelector((state: any) => state.userData.user);
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()

  console.log("patient", patient);
  useEffect(() => {
    // Set form initial values when activeNotes changes
    if (activeNotes) {
      form.setFieldsValue({
        title: activeNotes.title,
        description: activeNotes.description,
        costs: activeNotes.costs,
        date: activeNotes.date ? dayjs(activeNotes.date) : null,
        user: activeNotes.userId,
        paymentType: activeNotes.paymentType,
      });
    } else {
      // Reset form fields if activeNotes is null or undefined
      form.resetFields();
    }
  }, [activeNotes, form]);
  const handleOk = () => {
    onCloseModal();
  };
  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    if (activeNotes) {
      // EDIT Notes
      axios
        .put(
          `${BASE_API_URL}/patientDetails/notes/${params.id}/${activeNotes._id}`,
          {
            title: values.title,
            description: values.description,
            costs: values.costs,
            date: values.date,
            userId: values.user,
            paymentType: values.paymentType,
          }
        )
        .then(function (response) {
          onCloseModal();
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      // Continue to add notes
      if (isNotesUsed) {
        axios
          .put(`${BASE_API_URL}/patientDetails/notes/${params.id}`, {
            title: values.title,
            description: values.description,
            costs: values.costs,
            date: values.date,
            userId: values.user,
            paymentType: values.paymentType,
          })
          .then(function (response) {
            onCloseModal();
          })
          .catch(function (error) {
            // handle error
            console.error(error);
          });
      } else {
        // Start new notes
        axios
          .post(`${BASE_API_URL}/patientDetails/notes`, {
            patientId: params.id,
            clientId: userData.clientId,
            notes: [
              {
                title: values.title,
                description: values.description,
                costs: values.costs,
                date: values.date,
                userId: values.user,
                paymentType: values.paymentType,
                gender: patient?.gender,
              },
            ],
          })
          .then(function (response) {
            onCloseModal();
          })
          .catch(function (error) {
            // handle error
            console.error(error);
          });
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Modal
      title={t("Add Patient Notes")}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        //   initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="user"
          label={t("Doctor")}
          rules={[{ required: true, message: t("Please select doctor") }]}
        >
          <Select
            placeholder={t("Please select doctor")}
            //   onChange={onGenderChange}
            //   allowClear
            //   value={activeStaffMember}
          >
            {staffMembers?.map((item: any) => (
              <Option value={item._id} key={item._id}>
                {item.username}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("Title")}
          name="title"
          rules={[{ required: true, message: t("Please enter title") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Description")}
          name="description"
          rules={[{ required: false, message: t("Please enter description") }]}
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          label={t("Date")}
          name="date"
          rules={[{ required: false, message: t("Please enter date") }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item label={t("Costs")} name="costs">
          <Input />
        </Form.Item>
        <Form.Item
          name="paymentType"
          label={t("Payment Type")}
          rules={[{ required: true, message: t("Please select payment type") }]}
        >
          <Select placeholder={t("Please select payment type")}>
            <Option value={"card"} key={"card"}>
              {t("Card")}
            </Option>
            <Option value={"cash"} key={"cash"}>
              {t("Cash")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotesModal;

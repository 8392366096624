import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { Button, Form, InputNumber } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getHours, getMinutes } from "date-fns";
import { useTranslation } from "react-i18next";

const { Option } = Select;

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  onUpdateCosts: (costs: any) => void;
};

const AddPatientCostsModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  onUpdateCosts,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()

  useEffect(() => {
    // Set form initial values when activeNotes changes

    // Reset form fields if activeNotes is null or undefined
    form.resetFields();
  }, [form]);
  const handleOk = () => {
    onCloseModal();
  };
  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    onUpdateCosts(values.costs);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Modal
      title={t("Add Patient Notes")}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        //   initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t("Costs")}
          name="costs"
          rules={[{ required: true, message: t("Please enter costs") }]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPatientCostsModal;

import { Avatar, Dropdown, MenuProps } from "antd";
import { GlobalOutlined, UserOutlined } from "@ant-design/icons";

import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  user: any;
};
const UserMenu: React.FC<Props> = ({ user }) => {
  const userDetails = user && JSON.parse(user);
  const { t } = useTranslation();
  const navigation = useNavigate();
  const items: MenuProps["items"] = [
    {
      label: (
        <div>
          <div className="userProfile">
            {" "}
            {t("Welcome")} {userDetails?.firstName}
          </div>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          className="userMenuItem"
          onClick={() => {
            navigation(`users/${userDetails?.id}`);
          }}
        >
          {t("User Profile")}
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          className="userMenuItem"
          onClick={() => {
            navigation("/login");
            localStorage.removeItem("user");
          }}
        >
          {t("Logout")}
        </div>
      ),
      key: "2",
    },
  ];
  return (
    <Dropdown
      overlayClassName={"userMenuContainer"}
      menu={{ items }}
      trigger={["click"]}
    >
      <div className="userMenu">
        {/* <span className="username">
          {userDetails?.firstName?.charAt(0)}. {userDetails?.lastName}
        </span> */}
        <Avatar size={32} icon={<UserOutlined />} />
      </div>
    </Dropdown>
  );
};

export default UserMenu;

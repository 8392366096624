import React, { useEffect, useState } from "react";
import {
  RiseOutlined,
  CalendarOutlined,
  BarChartOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  SearchOutlined,
  EuroOutlined,
  TeamOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { Outlet } from "react-router-dom";

import {
  AutoComplete,
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Input,
  Layout,
  Menu,
  Row,
  Space,
  Spin,
  theme,
} from "antd";
import LogoIcon from "../../assets/fixmanLogo.svg";

import axios from "axios";
import { useTranslation } from "react-i18next";
import SelectLanguage from "./Components/SelectLanguage/SelectLanguage";
import UserMenu from "./Components/UserMenu/UserMenu";
import { AuthProvider } from "../../utils/useAuth";
import { BASE_API_URL } from "../../utils/consts";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../utils/redux/slices/userDataSlice";
import { getPatients } from "../../utils/redux/slices/patientsSlice";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
import "./Layout.scss";
import { UserRoleTypes } from "./types";
import { isAdmin } from "../../utils/permissions";
import { setWorkingPatterns } from "../../utils/redux/slices/workingPatternSlice";
const { Header, Content, Footer, Sider } = Layout;

type Props = {
  children?: any;
};

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  access?: React.ReactNode
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    access,
  } as MenuItem;
}

const LayoutContainer: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState<any>(undefined);
  const [patientsList, setPatients] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);
  const isLoaderActive = useSelector(
    (state: any) => state.loader.isLoaderActive
  );
  const patients = useSelector((state: any) => state.patients.patients);
  const loggedInUser: any = localStorage.getItem("user");
  const getLanguage = localStorage.getItem("userLanguage");
  const user = localStorage.getItem("user") ?? undefined;
  useEffect(() => {
    i18n.changeLanguage(getLanguage?.toString());
  }, [getLanguage]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const items: MenuItem[] = [
    getItem(t("Dashboard"), "0", <HomeOutlined />, [], UserRoleTypes.ADMIN),
    getItem(
      t("Appointments"),
      "1",
      <BarChartOutlined />,
      [],
      UserRoleTypes.USER
    ),
    getItem(t("Schedules"), "4", <CalendarOutlined />, [], UserRoleTypes.USER),
    getItem(t("Leaves"), "5", <UserOutlined />, [], UserRoleTypes.ADMIN),
    getItem(t("Members"), "2", <TeamOutlined />, [], UserRoleTypes.USER),
    getItem(t("Patients"), "3", <TeamOutlined />, [], UserRoleTypes.USER),
    getItem(t("Objectives"), "7", <RiseOutlined />, [], UserRoleTypes.ADMIN),
    getItem(t("Transactions"), "6", <EuroOutlined />, [], UserRoleTypes.ADMIN),

    //   getItem('User', 'sub1', <UserOutlined />, [
    //     getItem('Tom', '3'),
    //     getItem('Bill', '4'),
    //     getItem('Alex', '5'),
    //   ]),
    //   getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
    //   getItem('Uloguj se', '10', <FileOutlined />),
  ];

  useEffect(() => {
    if (!loggedInUser) {
      navigation("login");
    }
    dispatch(setUserData(loggedInUser ? JSON.parse(loggedInUser) : {}));
  }, [loggedInUser]);

  useEffect(() => {
    if (userData?.clientId) {
      dispatch(setLoader(true));
      axios
        .get(`${BASE_API_URL}/${userData?.clientId}/patients`)
        .then((res) => {
          dispatch(getPatients(res.data));
          const newPatientData = res.data.map((item: any) => ({
            user: {
              file: item.file,
              _id: item._id,
              userId: item.userId,
              email: item.email,
              phoneNo: item.phoneNo,
              gender: item.gender,
              birthDay: item.birthDay,
              jmbg: item.jmbg,
              address: item.address,
              specialNotes: item.specialNotes,
              costs: item.costs,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              __v: item.__v,
            },
            label: item.name,
            key: item._id,
            value: item._id,
          }));
          setPatients(newPatientData);
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoader(false)));
    }
  }, [userData]);

  useEffect(() => {
    const path = location?.pathname;
    if (path === "/") {
      setActiveMenu("0");
    } else if (path.includes("appointments")) {
      setActiveMenu("1");
    } else if (path.includes("users")) {
      setActiveMenu("2");
    } else if (path.includes("patient")) {
      setActiveMenu("3");
    } else if (path.includes("schedules")) {
      setActiveMenu("4");
    } else if (path.includes("leaves")) {
      setActiveMenu("5");
    } else if (path.includes("payments")) {
      setActiveMenu("6");
    } else if (path.includes("objectives")) {
      setActiveMenu("7");
    }
  }, []);

  const onClickMenu = (item: any, key: any, keyPath: any, domEvent: any) => {
    setActiveMenu(key);
    if (Number(key) === 0) {
      navigation("/");
    }
    if (Number(key) === 1) {
      navigation("/appointments");
    }
    if (Number(key) === 2) {
      navigation("/users");
    }
    if (Number(key) === 3) {
      navigation("/patients");
    }
    if (Number(key) === 4) {
      navigation("/schedules");
    }
    if (Number(key) === 5) {
      navigation("/leaves");
    }
    if (Number(key) === 6) {
      navigation("/payments");
    }
    if (Number(key) === 7) {
      navigation("/objectives");
    }
  };

  const onSelect = (data: string, option: any) => {
    navigation(`patients/${option.key}`);
    // window.location.reload();
  };

  const onChange = (data: string) => {
    // setValue(data);
  };

  return (
    <AuthProvider user={user ? JSON.parse(user) : ""}>
      {/* <div className="loadingPage">
		<Spin />
		</div> */}
      <>
        <div className="mobileNavigation">
          <Button.Group>
            <Button type="primary" onClick={() => navigation(`appointments`)}>
              <CalendarOutlined />
            </Button>
            <Button type="primary" onClick={() => navigation(`patients`)}>
              <UsergroupAddOutlined />
            </Button>
          </Button.Group>
        </div>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            collapsible
            collapsed={collapsed}
            theme={"light"}
            className="sidebar"
            onCollapse={(value) => setCollapsed(value)}
          >
            <div className="logoMe">
              <img src={LogoIcon} />
            </div>
            <Menu
              // defaultSelectedKeys={["1"]}
              theme={"light"}
              selectedKeys={[activeMenu]}
              mode="inline"
              onClick={({ item, key, keyPath, domEvent }: any) =>
                onClickMenu(item, key, keyPath, domEvent)
              }
              //   items={items}
            >
              {items?.map((item: any) => {
                if (item.access === UserRoleTypes.ADMIN) {
                  if (isAdmin(userData?.roles)) {
                    return (
                      <Menu.Item key={item.key} icon={item.icon}>
                        {item.label}
                      </Menu.Item>
                    );
                  } else {
                    return undefined;
                  }
                } else {
                  return (
                    <Menu.Item key={item.key} icon={item.icon}>
                      {item.label}
                    </Menu.Item>
                  );
                }
              })}
            </Menu>
          </Sider>
          <Layout className="mainLayout">
            <Header className="header">
              <Row justify="space-between">
                <Col className="companyName">{userData?.client?.name}</Col>
                <Col className="mainHeader">
                  {patientsList && (
                    <AutoComplete
                      style={{ width: 200 }}
                      options={patientsList}
                      className="globalPatientSearch"
                      onSelect={onSelect}
                      allowClear={true}
                      onChange={onChange}
                      placeholder={t("Patients")}
                      filterOption={(inputValue, option) => {
                        return (
                          option!.label
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        );
                      }}
                    >
                      <Input
                        suffix={
                          <SearchOutlined style={{ pointerEvents: "none" }} />
                        }
                      />
                    </AutoComplete>
                  )}
                  <SelectLanguage />
                  {isAdmin(userData?.roles) && (
                    <div className="settingsOptions">
                      <SettingOutlined
                        onClick={() => {
                          setActiveMenu("-1");
                          navigation("/settings");
                        }}
                      />
                    </div>
                  )}
                  <UserMenu user={user} />
                </Col>
              </Row>
            </Header>
            <Content style={{ margin: "0 16px" }}>
              {/* <Breadcrumb style={{ margin: "16px 0" }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>{location.pathname.slice(1)}</Breadcrumb.Item>
              </Breadcrumb> */}
              <div
                style={{
                  //   padding: 24,
                  marginTop: 16,
                  marginBottom: 16,
                  minHeight: 360,
                  //   background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                {/* {children} */}
                <Outlet />
              </div>
            </Content>
          </Layout>
        </Layout>
      </>
      {isLoaderActive && (
        <div className="spinnerContainer">
          <Spin />
        </div>
      )}
    </AuthProvider>
  );
};

export default LayoutContainer;

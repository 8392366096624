import { Button, Divider, Modal, Space, Table, TableProps, Tag } from "antd";
import React, { useEffect, useState } from "react";
import WorkingPatternModal from "../../../Components/Modals/WorkingPatternModal/WorkingPatternModal";
import axios from "axios";
import { BASE_API_URL } from "../../../utils/consts";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  deleteWorkingPattern,
  setWorkingPatterns,
} from "../../../utils/redux/slices/workingPatternSlice";
import WorkingPatternDetails from "./WorkingPatternDetails";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/useAuth";
import { isAdmin } from "../../../utils/permissions";

type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const data: DataType[] = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
const WorkingPattern: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser: any = useAuth();
  const userData = useSelector((state: any) => state.userData.user);
  const [detailsPage, setDetailsPage] = useState<boolean>(false);
  const workingPatterns = useSelector(
    (state: any) => state.workingPattern.contracts
  );

  useEffect(() => {
    if (userData?.clientId) {
      axios
        .get(`${BASE_API_URL}/${userData?.clientId}/working-patterns`)
        .then((res) => {
          console.log("res.data,", res.data);
          dispatch(setWorkingPatterns(res.data));
        });
    }
  }, [userData, dispatch]);
  const columns: TableProps<DataType>["columns"] = [
    {
      title: t("Contract Name"),
      dataIndex: "contractName",
      key: "contractName",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: t("Total Week Hours"),
      dataIndex: "totalWeekHours",
      key: "totalWeekHours",
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Action"),
      dataIndex: "delete",
      key: "delete",
      render: (text, row: any) => (
        <>
          <Button
            onClick={() => {
              navigate(`/settings/working-pattern/${row._id}`);
            }}
          >
            {t("View")}
          </Button>
          <Button
            onClick={() => {
              axios
                .delete(`${BASE_API_URL}/working-patterns/${row._id}`)
                .then((res) => {
                  dispatch(deleteWorkingPattern(row._id));
                });
            }}
          >
            {t("Delete")}
          </Button>
        </>
      ),
    },
  ];
  console.log("workingPatterns", workingPatterns);
  return (
    <>
      {detailsPage ? (
        <WorkingPatternDetails />
      ) : (
        <>
          {isAdmin(authUser.roles) && (
            <Button
              type="primary"
              onClick={() => navigate("/settings/working-pattern/new")}
            >
              {t("Add Contract")}
            </Button>
          )}
          <Divider />
          {/* <WorkingPatternModal /> */}
          <Table
            // onRow={(record: any, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       console.log("events", record);
            //       navigate(`/settings/working-pattern/${record._id}`);
            //     }, // click row
            //   };
            // }}
            columns={columns}
            dataSource={workingPatterns}
          />
        </>
      )}
    </>
  );
};
export default WorkingPattern;

import React from "react";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import { constants } from "../../utils/consts";
import { useSelector } from "react-redux";

type Props = {
  needToPaid: any;
  totalPaid: any;
};
const PatientStatistic: React.FC<Props> = ({ needToPaid, totalPaid }) => {
  const { t } = useTranslation();
  const userData = useSelector((state: any) => state.userData.user);
  const renderNeedToPaidIcon = () => {
    if (needToPaid >= 0) {
      return <ArrowDownOutlined />;
    } else {
      return <ArrowUpOutlined />;
    }
  };

  console.log("userData", userData);
  const calculateCurrency = () => {
    if (userData.client.currency === "Dinar") {
      return "RSD";
    } else if (userData.client.currency === "Euro") {
      return "EUR";
    } else {
      return "$";
    }
  };
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title={t("Paid")}
            value={totalPaid}
            precision={1}
            valueStyle={{ color: "#3f8600" }}
            prefix={<ArrowUpOutlined />}
            suffix={calculateCurrency()}
          />
        </Card>
      </Col>
      <Col span={12}>
        <Card bordered={false}>
          <Statistic
            title={`${needToPaid >= 0 ? t("Debt") : t("Overdid")}`}
            value={needToPaid}
            precision={1}
            valueStyle={{ color: needToPaid >= 0 ? "#cf1322" : "#3f8600" }}
            prefix={renderNeedToPaidIcon()}
            suffix={calculateCurrency()}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default PatientStatistic;

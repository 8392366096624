import axios from "axios";
import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import Table from "antd/es/table";
import { BASE_API_URL, constants } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useSelector } from "react-redux";

type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}
const Payments: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);
  useEffect(() => {
    if (userData.clientId) {
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/patientsDetails`)
        .then((res) => {
          const notesArrays = res.data.map((obj: any) => obj.notes);

          // Use reduce to flatten the array of arrays into a single array
          const allNotes = notesArrays.reduce(
            (acc: any, notes: any) => acc.concat(notes),
            []
          );

          setPayments(allNotes);
        })
        .catch((error) => console.error(error));
    }
  }, [userData]);

  const calculateCurrency = () => {
    if (userData.client.currency === "Dinar") {
      return "RSD";
    } else if (userData.client.currency === "Euro") {
      return "EUR";
    } else {
      return "$";
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: t("Costs"),
      dataIndex: "costs",
      key: "costs",
      render: (text) => (
        <>
          {text} {calculateCurrency()}
        </>
      ),
    },
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (value) => format(new Date(value), "d-MMM-yyyy"),
    },
    {
      title: t("Title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("Description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Doctor"),
      dataIndex: "userId",
      key: "userId",
      render: (row) => {
        return row?.username;
      },
    },
  ];

  return (
    <div className="mainContainer">
      <Table columns={columns} dataSource={payments} />
    </div>
  );
};

export default Payments;

import React, { useEffect, useState } from "react";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getHours, getMinutes } from "date-fns";
import { useTranslation } from "react-i18next";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  onAddLeave: (costs: any) => void;
};

const UserObjectiveModal: React.FC<Props> = ({
  isOpen,
  onCloseModal,
  onAddLeave,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()

  useEffect(() => {
    // Set form initial values when activeNotes changes

    // Reset form fields if activeNotes is null or undefined
    form.resetFields();
  }, [form]);
  const handleOk = () => {
    onCloseModal();
  };
  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    onAddLeave(values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Modal
      title={t("Add Objective")}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        //   initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t("Objective Type")}
          name="type"
          rules={[
            { required: true, message: t("Please enter objective type") },
          ]}
        >
          <Select
            placeholder={t("Objective Type")}
            // onChange={onGenderChange}
            //   allowClear
            value={""}
          >
            <Option
              value={"Obrazovanje i karijera"}
              key={"Obrazovanje i karijera"}
            >
              {t("Obrazovanje i karijera")}
            </Option>
            <Option value={"Finansijski ciljevi"} key={"Finansijski ciljevi:"}>
              {t("Finansijski ciljevi")}
            </Option>
            <Option value={"Lični razvoj"} key={"Lični razvoj:"}>
              {t("Lični razvoj")}
            </Option>
            <Option value={"Porodica i odnosi"} key={"Porodica i odnosi"}>
              {t("Porodica i odnosi")}
            </Option>
            <Option value={"Društveni angažman"} key={"Društveni angažman"}>
              {t("Nurse")}
            </Option>
            <Option value={"Putovanja i avanture"} key={"Putovanja i avanture"}>
              {t("Putovanja i avanture")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item label={t("Description")} name="description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="deadline"
          label="Deadline"
          rules={[{ required: true, message: t("Please enter deadline") }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserObjectiveModal;

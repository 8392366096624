import { Button, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../utils/useAuth";
import axios from "axios";
import { BASE_API_URL } from "../../../utils/consts";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../utils/redux/slices/userDataSlice";
import { useEffect } from "react";

const { Option } = Select;

const GeneralSettings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userData.user);
  const authUser: any = useAuth();

  useEffect(() => {
    console.log("dddd", userData?.client);
    if (userData?.client?.name) {
      form.setFieldsValue({
        companyName: userData?.client?.name,
        currency: userData?.client?.currency,
      });
    }
  }, [userData, form]);
  const onFinish = (value: any) => {
    axios
      .put(`${BASE_API_URL}/client/${authUser.clientId}`, {
        name: value.companyName,
        currency: value.currency,
        currencyIcon: value.currency,
      })
      .then((res) => {
        let updatedUserData: any = JSON.parse(JSON.stringify(userData));
        updatedUserData.client.name = res.data.name;
        updatedUserData.client.currency = res.data.currency;
        updatedUserData.client.currencyIcon = res.data.currency;
        dispatch(setUserData(updatedUserData));
        localStorage.setItem("user", JSON.stringify(updatedUserData));
      });
  };

  const onFinishFailed = (value: any) => {
    console.log("values error", value);
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={t("Company Name")}
        name="companyName"
        tooltip={t("This is a required field")}
        rules={[{ required: true, message: t("Please enter name") }]}
      >
        <Input placeholder={t("Enter Company Name")} />
      </Form.Item>
      <Form.Item
        name="currency"
        label={t("Currency")}
        rules={[
          {
            required: true,
            message: t("Please select currency type"),
          },
        ]}
      >
        <Select placeholder={t("Please select currency type")}>
          <Option value={"Dinar"} key={"dinar"}>
            {t("Dinar")}
          </Option>
          <Option value={"Euro"} key={"euro"}>
            {t("Euro")}
          </Option>
          <Option value={"Dollar"} key={"dollar"}>
            {t("Dollar")}
          </Option>
        </Select>
      </Form.Item>
      {/* <Form.Item
        label="Field B"
        tooltip={{
          title: "Tooltip with customize icon",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Input placeholder="input placeholder" />
      </Form.Item> */}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t("Save")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GeneralSettings;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Tabs, TabsProps, Tag } from "antd";
import { CameraOutlined } from "@ant-design/icons";

import { useNavigate, useParams } from "react-router-dom";
import { endOfMonth, startOfMonth, subDays } from "date-fns";
import { useAuth } from "../../utils/useAuth";
import UserPayment from "./Components/UserPayment/UserPayment";
import UserLeaves from "./Components/UserLeaves/UserLeaves";
import { BASE_API_URL } from "../../utils/consts";
import UserObjectives from "./Components/UserObjectives/UserObjectives";
import { useTranslation } from "react-i18next";
import mAvatar from "../../assets/m-avatar.png";
import wAvatar from "../../assets/w-avatar.png";
import "./UserProfile.scss";
import UserProfileModal from "../../Components/Modals/UserProfileModal/UserProfileModal";
import { isAdmin } from "../../utils/permissions";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
import * as filestack from "filestack-js";
import UserProfileData from "./Components/UserProfileData/UserProfileData";
import SelectLanguage from "../../Components/Layout/Components/SelectLanguage/SelectLanguage";
import { setWorkingPatterns } from "../../utils/redux/slices/workingPatternSlice";
import { setClientLeaves } from "../../utils/redux/slices/clientLeaves";
type Props = {};

const client: any = filestack.init("A5UiH0QvQvOrqm17AXWcQz", {
  version: "3", // Specify version 3 for API V3
});
const options = {
  accept: ["image/jpeg", "image/png", "image/*"],
  fromSources: ["local_file_system"],
  maxSize: 3,
};
// const client = filestack.init("A5UiH0QvQvOrqm17AXWcQz");

// const options = {
//   fromSources: ["local_file_system", "instagram", "facebook"],
// };

const UserProfile: React.FC<Props> = () => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedMonth, setSelectedMonth] = useState<any>(new Date());
  const [userDetails, setUserDetails] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);
  const clientLeaves = useSelector((state: any) => state.clientLeaves);
  const [locations, setLocations] = useState<any>(undefined);
  console.log("userDetails", userDetails?.user?.amount);
  const [isUserProfileModalOpen, setUserProfileModalOpen] =
    useState<boolean>(false);
  const authUser: any = useAuth();
  const isOwnProfile = authUser && authUser.id === params.id;
  useEffect(() => {
    const containsAdminRole = authUser.roles.some(
      (item: any) => item.includes("ADMIN") || item.includes("admin")
    );
    if (isOwnProfile || containsAdminRole) {
    } else {
      navigate("/");
    }
  }, [authUser, isOwnProfile, navigate]);
  const onChange = (key: string) => {};

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("Profile"),
      children: (
        <UserProfileData
          userDetails={userDetails}
          onUpdateUser={() => setUserProfileModalOpen(true)}
        />
      ),
    },
    {
      key: "2",
      label: t("Payments"),
      children: (
        <UserPayment
          userDetails={userDetails}
          onChangeDate={(date: any) => setSelectedMonth(date)}
        />
      ),
    },
    {
      key: "3",
      label: t("Leaves"),
      children: <UserLeaves userDetails={userDetails} />,
      //   disabled: isOwnProfile || isAdmin(userDetails?.user?.roles),
    },
    {
      key: "4",
      label: t("Objectives"),
      children: <UserObjectives userDetails={userDetails} />,
      //   disabled: isOwnProfile || isAdmin(userDetails?.user?.roles),
    },
  ];

  useEffect(() => {
    dispatch(setLoader(true));
    axios
      .get(
        `${BASE_API_URL}/users/${params.id}/${startOfMonth(
          new Date(selectedMonth)
        ).toISOString()}/${endOfMonth(new Date(selectedMonth)).toISOString()}`
      )
      .then((res) => {
        setUserDetails(res.data);
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoader(false)));
  }, [selectedMonth, params.id]);

  useEffect(() => {
    if (userData?.clientId) {
      axios
        .get(`${BASE_API_URL}/${userData?.clientId}/working-patterns`)
        .then((res) => {
          dispatch(setWorkingPatterns(res.data));
        })
        .catch((err) => console.error(err));
    }
  }, [userData, dispatch]);

  useEffect(() => {
    if (params.id !== "new") {
      axios
        .get(`${BASE_API_URL}/${userData?.clientId}/locations`)
        .then((res) => {
          setLocations(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.clientId) {
      axios
        .get(`${BASE_API_URL}/admin/${userData?.clientId}/leaves`)
        .then((res) => {
          dispatch(setClientLeaves(res.data));
        })
        .catch((err) => console.error(err));
    }
  }, [userData, dispatch]);

  const onUpdateUserProfile = (profileData: any) => {
    dispatch(setLoader(true));
    axios
      .put(`${BASE_API_URL}/users/${params.id}`, {
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        color: profileData.color,
        position: profileData.position,
        gender: profileData.gender,
        amount: profileData.amount,
        workingPatternId: profileData.workingPatternId,
        leaves: profileData.clientLeaves,
        contractStart: profileData.contractStart,
        locationId: profileData.location,
      })
      .then((res) => {
        axios
          .get(
            `${BASE_API_URL}/users/${params.id}/${startOfMonth(
              new Date(selectedMonth)
            ).toISOString()}/${endOfMonth(
              new Date(selectedMonth)
            ).toISOString()}`
          )
          .then((res) => {
            setUserDetails(res.data);
            setUserProfileModalOpen(false);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoader(false)));
  };

  const openFileStackDialog = () => {
    // client.picker().open();
    openPicker();
  };

  const onSuccess = (result: any) => {
    console.log("File uploaded successfully:", result);
    dispatch(setLoader(true));
    axios
      .put(`${BASE_API_URL}/users/${params.id}`, {
        avatar: result.filesUploaded[0],
      })
      .then((res) => {
        axios
          .get(
            `${BASE_API_URL}/users/${params.id}/${startOfMonth(
              new Date(selectedMonth)
            ).toISOString()}/${endOfMonth(
              new Date(selectedMonth)
            ).toISOString()}`
          )
          .then((res) => {
            setUserDetails(res.data);
            setUserProfileModalOpen(false);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err))
      .finally(() => dispatch(setLoader(false)));
    // Save the response to the component's state
  };

  const onError = (error: any) => {
    console.error("File upload error:", error);
    // Handle upload error here
  };

  const openPicker = async () => {
    try {
      const response = await client
        .picker({
          accept: ["image/jpeg", "image/png", "image/*"],
          fromSources: ["local_file_system"],
          maxSize: 3000000,
          onUploadDone: onSuccess,
          storeTo: {
            location: "s3", // Specify the storage location (e.g., S3)
            path: "folder_name/", // Specify the folder name
          },
        })
        .open();
    } catch (error) {
      console.error("Filestack error:", error);
    }
  };

  return (
    <>
      <div className="profileHeader">
        <div className="userData">
          <div
            className="userPhoto"
            style={{
              border: `3px solid ${userDetails?.user?.color ?? "black"}`,
            }}
          >
            {userDetails?.user?.avatar?.url ? (
              <img src={userDetails?.user?.avatar?.url} alt="W Profile" />
            ) : userDetails?.user?.gender === "male" ? (
              <img src={mAvatar} alt="M Profile" />
            ) : userDetails?.user?.gender === "female" ? (
              <img src={wAvatar} alt="W Profile" />
            ) : undefined}
            <button onClick={() => openFileStackDialog()}>
              <CameraOutlined />
            </button>
          </div>
          <div className="userInfo">
            <div className="name">
              {userDetails?.user?.firstName} {userDetails?.user?.lastName}
            </div>
            <div className="position">
              <div className="info">
                <span>
                  <Tag
                    color={userDetails?.user?.color}
                    key={userDetails?.user?.position}
                  >
                    {t(userDetails?.user?.position)}
                  </Tag>
                </span>
              </div>
            </div>
            <div className="gender">{t(userDetails?.user?.gender)}</div>
          </div>
        </div>
        <div className="languageSelect">
          <SelectLanguage />
        </div>
      </div>
      <div className="userTabs">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      {/* <Row>
        <Col lg={5} xs={24}>
          <div className="userProfileContainer">
            <div
              className="editUserProfile"
              onClick={() => setUserProfileModalOpen(true)}
            >
              <EditOutlined />
            </div>
            <div
              className="userPhoto"
              style={{ border: `5px solid ${userDetails?.user?.color}` }}
            >
              {userDetails?.user?.avatar?.url ? (
                <img src={userDetails?.user?.avatar?.url} alt="W Profile" />
              ) : userDetails?.user?.gender === "male" ? (
                <img src={mAvatar} alt="M Profile" />
              ) : userDetails?.user?.gender === "female" ? (
                <img src={wAvatar} alt="W Profile" />
              ) : undefined}
              <button onClick={() => openFileStackDialog()}>
                <CameraOutlined />
              </button>
            </div>
    
            <div className="userInfo">
              <div className="info">
                {t("First Name")}:<span>{userDetails?.user?.firstName}</span>
              </div>
              <div className="info">
                {t("Last Name")}:<span>{userDetails?.user?.lastName}</span>
              </div>
              <div className="info">
                {t("Name")}:<span>{userDetails?.user?.username}</span>
              </div>
              <div className="info">
                {t("Email")}:<span>{userDetails?.user?.email}</span>
              </div>
              <div className="info">
                {t("Gender")}:<span>{t(userDetails?.user?.gender)}</span>
              </div>
              <div className="info">
                {t("Position")}:
                <span>
                  <Tag
                    color={userDetails?.user?.color}
                    key={userDetails?.user?.position}
                  >
                    {t(userDetails?.user?.position)}
                  </Tag>
                </span>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={19} xs={24}>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Col>
      </Row> */}
      {isUserProfileModalOpen && (
        <UserProfileModal
          isOpen={isUserProfileModalOpen}
          onCloseModal={() => setUserProfileModalOpen(false)}
          onUpdateProfile={onUpdateUserProfile}
          userDetails={userDetails}
          locations={locations}
        />
      )}
    </>
  );
};

export default UserProfile;

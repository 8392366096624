import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "./Login.scss";
import { BASE_API_URL } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setUserData } from "../../utils/redux/slices/userDataSlice";
import { ReactComponent as LogoIcon } from "../../assets/fixmanLogo.svg";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
type FieldType = {
  email?: string;
  password?: string;
  remember?: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form: any = useRef(null);
  const [error, setError] = useState<boolean>(false);
  const onFinish = (values: any) => {
    dispatch(setLoader(true));
    axios
      .post(`${BASE_API_URL}/auth/signin`, {
        email: values.email,
        password: values.password,
      })
      .then(function (response) {
        // handle success
        navigate("/appointments");
        dispatch(setUserData(response.data));
        localStorage.setItem("user", JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log("error", error);
        setError(true);
        // handle error
        console.error(error);
      })
      .finally(function () {
        dispatch(setLoader(false));
      });
  };

  useEffect(() => {
    form?.current?.getFieldInstance("email").focus();
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <div className="loginPage">
      <div className="loginImageBg hide-md"></div>
      <div className="login">
        <div className="loginHeader">
          <LogoIcon />
        </div>
        <div className="formContainer">
          <Form
            name="basic"
            ref={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ width: "100%" }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              name="email"
              label={t("Email")}
              rules={[
                { required: true, message: t("Please input your email!") },
              ]}
            >
              <Input placeholder={t("Email")} />
            </Form.Item>

            <Form.Item<FieldType>
              name="password"
              className="loginPassword"
              label={t("Password")}
              rules={[
                { required: true, message: t("Please input your password!") },
              ]}
            >
              <Input.Password placeholder={t("Password")} />
            </Form.Item>

            {/* <Form.Item<FieldType>
            name="remember"
            valuePropName="checked"
            wrapperCol={{ offset: 8, span: 16 }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}
            {error && (
              <div className="errorLogin">
                {t("Please check email or password")}
              </div>
            )}
            <Form.Item wrapperCol={{ span: 16 }}>
              <Button type="primary" htmlType="submit">
                {t("Log In")}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* <div className="footerLogin">footer</div> */}
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import LayoutContainer from "./Components/Layout/Layout";
import { Routes, Route } from "react-router-dom";

import Login from "./Pages/Login/Login";
import Patients from "./Pages/Patients/Patients";
import Staff from "./Pages/Staff/Staff";
import Appointments from "./Pages/Appointments/Appointments";
import PatiendProfile from "./Pages/PatientProfile/PatientProfile";
import Schedules from "./Pages/Schedules/Schedules";
import UserProfile from "./Pages/UserProfile/UserProfile";
import Leaves from "./Pages/Leaves/Leaves";
import Payments from "./Pages/Payments/Payments";
import Objectives from "./Pages/Objectives/Objectives";
import Dashboard from "./Pages/Dashboard/Dashboard";
import "./App.scss";
import Settings from "./Pages/Settings/Settings";
import ActiveAccount from "./Pages/ActiveAccount/ActiveAccount";
import AllCalendar from "./Pages/Calendars/Calendars";
import WorkingPatternDetails from "./Pages/Settings/WorkingPattern/WorkingPatternDetails";
import CreateLeave from "./Pages/Settings/LeavesSettings/CreateLeave/CreateLeave";
import LocationDetails from "./Pages/Settings/LocationsSettings/LocationDetails";

const AuthLayout = () => {
  return <div>Auth.....</div>;
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<LayoutContainer />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="patients" element={<Patients />} />
        <Route path="patients" element={<Patients />} />
        <Route path="appointments" element={<Appointments />} />
        <Route path="leaves" element={<Leaves />} />
        <Route path="patients/:id" element={<PatiendProfile />} />
        <Route path="schedules" element={<Schedules />} />
        <Route path="payments" element={<Payments />} />
        <Route path="users" element={<Staff />} />
        <Route path="objectives" element={<Objectives />} />
        <Route path="users/:id" element={<UserProfile />} />
        <Route path="settings" element={<Settings />} />
        <Route
          path="settings/working-pattern/:id"
          element={<WorkingPatternDetails />}
        />
        <Route path="settings/leaves/:id" element={<CreateLeave />} />
        <Route path="settings/location/:id" element={<LocationDetails />} />

        <Route path="calendars" element={<AllCalendar />} />
        <Route element={<AuthLayout />}>
          {/* <Route path="logout" action={logoutUser} /> */}
        </Route>
      </Route>
      <Route path="activate/:token" element={<ActiveAccount />} />
      <Route path="login" element={<Login />} />
    </Routes>
  );
}

export default App;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  // Events Metadata
  contracts: undefined,
};

export const workingPatternSlice = createSlice({
  name: "workingPatternSlice",
  initialState,
  reducers: {
    setWorkingPatterns(state: any, action: PayloadAction<any>) {
      console.log("state1", action.payload);
      return {
        ...state,
        contracts: action.payload,
      };
    },
    deleteWorkingPattern(state: any, action: PayloadAction<any>) {
      console.log("ddreeducer", action.payload);
      console.log("state2", state.contracts);
      //   state.contracts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWorkingPatterns, deleteWorkingPattern } =
  workingPatternSlice.actions;

export default workingPatternSlice.reducer;
